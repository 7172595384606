<template>
    <HeaderView />

    <div class="container-fluid">

        <BannerView v-if="showBanner" />

        <div class="container mt-5">

            <div class="row">
                <div class="mission-container">
                    <p class="caption">Beveiliging</p>
                    <p>
                        SereneFuture Beveiligingsbeleid: Uw Pensioentoekomst, Ons Fort
                    </p>
                </div>
            </div>
        </div>

        <div class="header-image image-6"></div>

        <div class="container mt-5">
            <div class="row">
                <p>In de wereld van pensioenen is niet alleen de zekerheid van uw financiële toekomst van belang, maar ook
                    de beveiliging van de gegevens die daaraan gekoppeld zijn. Bij SereneFuture combineren we deze twee
                    kernprincipes in een onwrikbare belofte: het beschermen van uw pensioen en de privacy van uw gegevens
                    met een standaard die ver boven de industriële normen ligt.</p>
                <div class="news-container">
                    <h2>Standaardnormen: Onze Vertrekpunten, Niet Onze Bestemming</h2>
                    <p>In de beveiligingswereld kunnen normen vaak worden gezien als pieken om te bereiken. Bij SereneFuture
                        beschouwen we ze echter als basiskampen van waaruit we nog hoger streven. De algemeen geaccepteerde
                        normen zijn voor ons slechts het minimum, het begin van onze tocht naar superieure beveiliging.</p>
                    <h2>Een Selectie van Onze Maatregelen:</h2>
                    <ul>
                        <li><strong>End-to-end encryptie:</strong> Alle communicatie, of het nu gaat om uw pensioendetails
                            of persoonlijke gegevens, wordt versleuteld. Hiermee waarborgen we de integriteit en
                            vertrouwelijkheid van uw gegevens tijdens elke interactie.</li>
                        <li><strong>Two-factor authenticatie (2FA):</strong> Voorbij het wachtwoord; 2FA zorgt voor een
                            extra beveiligingslaag, waardoor uw pensioengegevens alleen toegankelijk zijn voor wie ze echt
                            moeten zien: u.</li>
                        <li><strong>ISO/IEC 27001 & verder:</strong> Terwijl velen stoppen bij deze erkende standaard, zien
                            wij dit als ons startpunt, en voegen er voortdurend nieuwe lagen van beveiligingsprotocollen aan
                            toe.</li>
                        <li><strong>Voortdurende penetratietesten:</strong> Door experts uit te dagen onze digitale wallen
                            te doorbreken, blijven we altijd een stap voor op potentiële bedreigingen en zorgen we ervoor
                            dat uw pensioengegevens veilig zijn.</li>
                        <li><strong>Beveiligde datacentra van wereldklasse:</strong> Uw pensioendetails zijn veilig
                            opgeslagen in zwaar beveiligde faciliteiten, met continue surveillance en geavanceerde
                            beschermingsmaatregelen.</li>
                    </ul>
                    <h2>Pensioen en Privacy: Onze Tweeledige Belofte</h2>
                    <p>Het beschermen van uw pensioen gaat verder dan alleen financiële zekerheid; het betekent ook het
                        bewaken van de gegevens die daaraan verbonden zijn. In een tijdperk waarin gegevens een waardevolle
                        valuta zijn, verzekeren wij u ervan dat uw pensioengegevens bij ons worden behandeld met de uiterste
                        zorg, respect en, bovenal, beveiliging.</p>
                    <h2>Een Toekomst van Veiligheid en Zekerheid</h2>
                    <p>Bij SereneFuture doen we meer dan alleen plannen voor uw financiële toekomst; we bouwen ook aan een
                        bolwerk van beveiliging eromheen. Want een comfortabel pensioen is een pensioen dat niet alleen
                        rendabel, maar ook veilig is.</p>
                </div>
                <p>
                    Vertrouw op SereneFuture, waar uw pensioen en privacy altijd voorop staan.
                </p>
            </div>
        </div>

        <!-- <div class="header-image image-4" style="margin-top:3rem;"></div> -->
    </div>

    <FooterView />
</template>

<script>
import HeaderView from '@/components/HeaderView.vue';
import BannerView from '@/components/BannerView.vue';
import FooterView from '@/components/FooterView.vue';

export default {
    name: 'InfoBeveiliging',
    components: {
        HeaderView, BannerView, FooterView
    }
};
</script>

<style scoped>
.container-fluid {
    margin-bottom: 3em;
}

.mission-container {
    box-sizing: border-box;
    max-width: 73.125rem;
    padding-bottom: 1.3125rem;
    background: white;
}

.serene-future-mission {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 3.75rem;
}

.serene-future-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.journey-guide {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 1.75rem;
    margin-left: 2.9375rem;
}

.serene-future-text {
    box-sizing: border-box;
    flex: 0 1 auto;
    margin-left: 1.875rem;
}

.journey-guide1 {
    box-sizing: border-box;
    flex: 0 1 auto;
    margin-left: -1.1875rem;
}

.signature {
    font-size: 15pt;
    display: block;
    margin-top: 2rem;
}

.header-image {
    width: 100%;
    height: 11em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.image-1 {
    background-image: url(@/assets/carousel_2.jpg);
}

.image-2 {
    background-image: url(@/assets/about-us.jpg);
}

.image-3 {
    background-image: url(@/assets/office-with-logo.jpg);
}

.image-4 {
    background-image: url(@/assets/about-us-3.jpg);
}

.image-6 {
    background-image: url(@/assets/about-us-6.jpg);
}

.news-container {
    background: #f6f9ff;
    padding: 2.5em;
    margin-top: 2em;
    margin-bottom: 2em;
}

.news-container p {
    text-align: justify;
}

.news-container h2 {
    color: #444;
    font-weight: normal;
    margin-bottom: 0.5em;
    font-size: 1.9rem;
}
</style>