const seconds = 1000;
const minutes = 60 * seconds;

module.exports = {
    states_one: [
        {
            dilemmaId: "stap-1",
            title: 'Orientatiefilm',
            answers: [], duration: -1, startAfter: -1, isRead: true
        },
        {
            dilemmaId: "stap-1b",
            title: 'Introductie-email 1',
            answers: [], duration: -1, startAfter: 1, isRead: true
        },
        {
            dilemmaId: "stap-1c",
            title: 'Introductie-email 2',
            answers: [], duration: -1, startAfter: 1, isRead: true
        },
        {
            dilemmaId: "stap-2",
            title: 'Agenda bestuursvergadering',
            answers: [
                { index: 0, title: "Afwachten", result: [-1, 1, 0, 0, 0] },
                { index: 1, title: "PUO vragen", result: [2, 1, 0, 0, 1] },
                { index: 2, title: "Waarschuwing plaatsen", result: [-1, 0, -2, 1, 1] },
                { index: 3, title: "Deelnemers mailen", result: [1, 0, 0, 1, 1] },
                { index: 4, title: "Melden bij toezichthouder", result: [2, 0, 0, 0, 0] },
            ],
            startAfter: 1 * seconds,
            duration: -1,
            startsTheGame: true
        },
        // {
        //     dilemmaId: "stap-3",
        //     title: 'PUO gebruikt MASS Mailing',
        //     answers: [
        //         { index: 0, title: "Intern onderzoek", result: [-2, 0, 0, 0, -1] },
        //         { index: 1, title: "Welke adressen", result: [1, 0, 0, 0, -1] },
        //         { index: 2, title: "Betrek MASS Mailing", result: [2, 0, 0, 0, 1] },
        //         { index: 3, title: "Vertel MASS Mailing van datalek", result: [-1, 0, -1, 0, -1] },
        //     ],
        //     startAfter: 5 * minutes,
        //     duration: -1
        // },
        {
            dilemmaId: "stap-4",
            title: 'De PUO krijgt meldingen van loginproblemen',
            answers: [
                { index: 0, title: "Wacht af", result: [-1, -1, -2, -1, -1] },
                { index: 1, title: "Schakel experts in", result: [2, 0, 0, 0, -1] },
                { index: 2, title: "Schakel portaal uit", result: [1, -1, 1, 1, -2] },
                { index: 3, title: "Vraag om informatie", result: [1, 1, 0, 0, -1] },
            ],
            startAfter: 10 * minutes,
            duration: -1
        },
        // {
        //     dilemmaId: "stap-5", title: 'Toezichthouder vraagt reactie', answers: [
        //         { index: 0, title: "Wacht af", result: [-2, 0, 0, 0, 0] },
        //         { index: 1, title: "Wijs naar MASS Mailing", result: [1, 0, 0, 0, 0] },
        //         { index: 2, title: "Afwachtende het onderzoek", result: [2, 0, 0, 0, 0] },
        //         { index: 3, title: "Wijs naar PUO", result: [-1, 0, 0, 0, 0] },
        //     ],
        //     delay: 5 * minutes,
        //     startAfter: 15 * minutes,
        //     duration: 5 * minutes
        // },
        // {
        //     dilemmaId: "stap-6",
        //     title: 'Ontkenning vanuit MASS Mailing',
        //     answers: [],
        //     startAfter: 18 * minutes,
        //     duration: -1
        // },
        {
            dilemmaId: "stap-7c", title: 'Bericht van PUO dat mailadressen echt zijn', answers: [],
            startAfter: 15 * minutes,
            duration: -1
        },
        {
            dilemmaId: "stap-7a", title: 'Voicemail doorgestuurd',
            answers: [],
            startAfter: 20 * minutes,
            duration: -1
        },
        {
            dilemmaId: "stap-7b", title: 'De voicemail', answers: [],
            startAfter: 20 * minutes + 1 * seconds,
            duration: -1,
            isVoicemail: true
        },
        {
            dilemmaId: "stap-8", title: 'Radioprogramma / Voicemail', answers: [
                { index: 0, title: "Reageer niet", result: [0, -1, -2, 0, 0] },
                { index: 1, title: "Generieke reactie", result: [1, 1, 1, 0, 0] },
                { index: 2, title: "Gedetailleerde reactie", result: [-1, -2, 1, 1, -1] },
                { index: 3, title: "Bezoek", result: [1, 1, 2, 1, 0] },
            ],
            startAfter: 20 * minutes,
            duration: -1,
            areMetersVisible: true
        },
        // {
        //     dilemmaId: "stap-9", title: 'Bericht van PUO over problemen bij uitkeringen', answers: [
        //         { index: 0, title: "Wacht af", result: [-2, -2, -2, -2, -2] },
        //         { index: 1, title: "Vraag om onderzoek", result: [1, -2, 0, 0, -1] },
        //         { index: 2, title: "Stopzetten", result: [1, -1, 0, -1, -1] },
        //         { index: 3, title: "Bel de bank", result: [2, -2, -1, 0, -2] },
        //     ],
        //     startAfter: 25 * minutes,
        //     duration: 5 * minutes
        // },
        // RONDE 2
        {
            dilemmaId: "pauze", title: 'PAUZE', answers: [],
            startAfter: 30 * minutes,
            duration: -1,
            isPause: true,
            isRound2: true,
            areMetersVisible: true,
            isRead: true,
        },
        {
            dilemmaId: "stap-10", title: 'Bericht van PUO inzake ransomware', answers: [],
            startAfter: 0 * minutes,
            duration: -1,
            isRound2: true,
        },
        {
            dilemmaId: "stap-11", title: 'Krant', answers: [],
            startAfter: 30 * seconds,
            duration: -1,
            isRound2: true,
            isRead: true
        },
        // {
        //     dilemmaId: "stap-12", title: 'Verzoek reactie PensioenPro', answers: [
        //         { index: 0, title: "Reageer niet", result: [-1, 0, -2, -2, 0] },
        //         { index: 1, title: "Geen statement", result: [0, 0, -1, -1, 0] },
        //         { index: 2, title: "Niet onze schuld", result: [-1, 1, 0, 0, 0] },
        //         { index: 3, title: "Geen resultaat nog", result: [1, 0, 1, 0, 0] },
        //     ],
        //     startAfter: 1 * minutes,
        //     duration: -1,
        //     isRound2: true,
        // },
        // {
        //     dilemmaId: "stap-13", title: 'Welke informatie opvragen bij PUO?', answers: [
        //         { index: 0, title: "Vraag geen specifieke informatie uit", result: [-1, -1, -1, -1, -2] },
        //         { index: 1, title: "Lijst ingeleverd", result: [0, 0, 0, 0, 0] },
        //     ],
        //     startAfter: 6 * minutes,
        //     duration: 5 * minutes,
        //     isRound2: true,
        // },
        // {
        //     dilemmaId: "stap-14", title: 'Bericht op LinkedIn', answers: [],
        //     startAfter: 6 * minutes,
        //     duration: -1,
        //     isRound2: true,
        // },
        {
            dilemmaId: "stap-15", title: 'Reactie met informatie van PUO', answers: [
                { index: 0, title: "Geen", result: [-2, -2, -2, -2, 2] },
                { index: 1, title: "Technisch/forensisch rapport", result: [2, 0, 0, 0, 1] },
                { index: 2, title: "Huidige impact", result: [1, 0, 0, 0, -1] },
                { index: 3, title: "Wacht af", result: [1, 0, 0, 0, -1] },
            ],
            startAfter: 1 * minutes,
            duration: -1,
            isRound2: true,
        },
        {
            dilemmaId: "stap-16", title: 'Bericht van PUO dat er gegevens op darkweb staan', answers: [],
            startAfter: 7 * minutes + 30 * seconds,
            duration: -1,
            isRound2: true,
        },
        // {
        //     dilemmaId: "stap-17", title: 'Bericht van ander fonds dat ze ook geraakt zijn', answers: [
        //         { index: 0, title: "Doe niks", result: [-2, 0, -1, 0, 0] },
        //         { index: 1, title: "Zoek contact", result: [2, 0, 0, 0, 0] },
        //         { index: 2, title: "Vraag PUO on duidelijkheid", result: [2, 0, 0, 0, 1] },
        //         { index: 3, title: "Geef info aan toezichthouder", result: [1, 0, 0, 0, 0] },
        //     ],
        //     startAfter: 15 * minutes,
        //     duration: -1,
        //     isRound2: true,
        // },
        // {
        //     dilemmaId: "stap-18", title: 'Bericht van PUO dat nieuwe administratiesysteem ook geraakt is', answers: [],
        //     startAfter: 16 * minutes,
        //     duration: -1,
        //     isRound2: true,
        // },
        {
            dilemmaId: "stap-19", title: 'Bericht van toezichthouder', answers: [
                { index: 0, title: "Reageer niet", result: [-3, 0, 0, 0, 0] },
                { index: 1, title: "Bel de toezichthouder", result: [0, 0, 0, 0, 0] },
            ],
            startAfter: 15 * minutes,
            duration: -1,
            isRound2: true,
            areMetersVisible: true
        },
        {
            dilemmaId: "stap-20", title: 'Bericht op Geenstijl', answers: [],
            startAfter: 20 * minutes,
            duration: -1,
            isRound2: true,
        },
        {
            dilemmaId: "stap-21b", title: 'Persbericht', answers: [],
            startAfter: 22 * minutes + 30 * seconds,
            duration: -1,
            isRound2: true,
        },
        {
            dilemmaId: "stap-21", title: 'Bericht van PUO omtrent de back-upmogelijkheden', answers: [],
            startAfter: 22 * minutes + 30 * seconds,
            duration: -1,
            isRound2: true,
        },
        {
            dilemmaId: "stap-22", title: 'Forensisch rapport', answers: [],
            startAfter: 22 * minutes + 30 * seconds,
            duration: -1,
            isRound2: true,
        },
        {
            dilemmaId: "stap-23", title: 'De hackers willen antwoord', answers: [
                // { index: 0, title: "Negeer de hackers", result: [-2, -2, -2, -2, -2] },
                // { index: 1, title: "Betaal de hackers", result: [-2, 1, 0, 0, 2] },
                // { index: 2, title: "Rek tijd", result: [1, -1, 0, 0, 0] },
                // { index: 3, title: "Betaal niet", result: [2, -2, 1, 1, -1] },
                { index: 0, title: "Betaal niet", result: [2, -2, 1, -1, -1] },
                { index: 1, title: "Betaal wel", result: [-2, 1, -1, 1, 2] },
            ],
            startAfter: 30 * minutes,
            duration: 15 * minutes,
            isRound2: true,
        },
        {
            dilemmaId: "einde", title: 'Einde', answers: [],
            startAfter: 45 * minutes,
            duration: -1,
            isEnd: true,
            isRound2: true,
            areMetersVisible: true,
            isRead: true
        },
    ]
}