<template>
    <div class="blue-card">
        <router-link :to="{ name: 'AdminCreateSession' }">
            <div class="menu-entry">
                <div class="menu-text">
                    <p>Nieuwe sessie</p>
                </div>
            </div>
        </router-link>
        <router-link :to="{ name: 'AdminSessions' }">
            <div class="menu-entry">
                <div class="menu-text">
                    <p>Sessies</p>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {

};
</script>

<style scoped>
.blue-card {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
    justify-content: flex-start;
    /* padding: 1.9375rem 1.28125rem 0.84375rem 1.34375rem; */
    padding: 0 1.2rem;
    margin-top: 1.75rem;
    background: var(--color-blue);
    /* width: 16rem; */
    /* margin-left: 1.5rem; */
    border-radius: 1.125rem;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
}

.menu-entry {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1.2rem;
    padding-bottom: 1rem;
    cursor: pointer;
}

a:not(:first-of-type) .menu-entry {
    border-top: 1px solid white;
}

.menu-entry:hover {
    /* background: rgba(255,255,255,0.5); */
    background-image: radial-gradient(circle, rgba(255, 255, 255, 0.3), transparent);
}

.menu-text {
    margin-left: 1rem;
    font: 500 1.9rem Roboto;
    color: white;
    padding-top: 0.6rem;
}

.menu-text {
    display: flex;
    align-items: baseline;
}
</style>
