<template>
    <div class="container-with-image-and-sections col-3-lg">
        <div class="profile-card">
            <router-link :to="{ name: 'Dashboard' }">
                <img class="img-content-33bd89f4" src="@/assets/logo.png" width="140" />
            </router-link>
        </div>
        <img class="image-container5 img-content-fdda30d4" />

        <ControlPanelMenu />

        <a class="primary-button" href="/">
            <svg viewBox="0 0 32 32" x="0" y="0" width="32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Icon_7"
                    d="M13.97245,22.54195c0.5009,0.601 1.3942,0.682 1.9953,0.181c0.601,-0.501 0.6822,-1.394 0.1813,-1.995zM10.36515,15.99995l-1.0884,-0.907c-0.4378,0.525 -0.4378,1.289 0,1.814zM16.14905,11.27195c0.5009,-0.601 0.4197,-1.494 -0.1813,-1.995c-0.6011,-0.501 -1.4944,-0.42 -1.9953,0.181zM21.63475,17.41695c0.7824,0 1.4166,-0.635 1.4166,-1.417c0,-0.782 -0.6342,-1.417 -1.4166,-1.417zM16.14905,20.72795l-4.6956,-5.635l-2.1767,1.814l4.6957,5.635zM11.45345,16.90695l4.6956,-5.635l-2.1766,-1.814l-4.6957,5.635zM10.36515,17.41695h11.2696v-2.834h-11.2696zM7.98605,7.98595c4.426,-4.426 11.6018,-4.426 16.0278,0l2.0034,-2.003c-5.5324,-5.533 -14.5022,-5.533 -20.0346,0zM24.01385,7.98595c4.4259,4.426 4.4259,11.602 0,16.028l2.0034,2.003c5.5327,-5.532 5.5327,-14.502 0,-20.034zM24.01385,24.01395c-4.426,4.426 -11.6018,4.426 -16.0278,0l-2.0034,2.003c5.5324,5.533 14.5022,5.533 20.0346,0zM7.98605,24.01395c-4.4259,-4.426 -4.4259,-11.602 0,-16.028l-2.0034,-2.003c-5.5325,5.532 -5.5325,14.502 0,20.034z"
                    fill="#5F8BFF" xmlns="http://www.w3.org/2000/svg" />
            </svg>
            &nbsp;&nbsp;WEBSITE
        </a>

    </div>
</template>

<script>
import { RouterLink } from 'vue-router';
import ControlPanelMenu from './ControlPanelMenu.vue';

export default {
    components: {
        ControlPanelMenu,
        RouterLink
    }
}
</script>

<style scoped>
:root {
    --bar-width: 16rem;
}

.profile-card {
    margin-top: -8px;
    margin-bottom: 8px;
}

.container-with-image-and-sections {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 0;
    align-items: center;
    justify-content: flex-start;
    /* width: 23.43%; */
    padding-top: 0.75rem;
    padding-right: 0.75rem;
    padding-bottom: 1.9375rem;
    padding-left: 1.9rem;
}

.primary-button {
    display: block;
    text-align: center;
    line-height: 5.5rem;
    text-decoration: none;
    width: 100%;
    height: 6rem;
    font-weight: 700;
    font-family: Roboto;
    color: #5f8bff;
    background: white;
    border: 0.1875rem solid #5f8bff;
    border-radius: 1.125rem;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    margin-top: 1.75rem;
}
</style>
