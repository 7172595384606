<template>
    <div class="row folded-corner-parent">
        <div class="serene-future2">
            <div class="container-section">
                <img class="image-container img-content-dbf415fc" />
                <div class="container-subsection">
                    <img class="image-container img-content-142be835" />
                </div>
            </div>
            <div class="future-container">
                <img class="image-container img-content-8a201692" />
            </div>
            <div class="serene-future">
                <p class="caption">SereneFuture</p>
                <p class="serene-future-text">
                    Welkom bij SereneFuture Pensioenfonds!
                    <br />
                    Bij SereneFuture begrijpen we dat de toekomst vol beloften en uitdagingen zit. Daarom hebben we
                    ons toegewijd aan het creëren van een solide basis voor een rustige en zekere toekomst voor al
                    onze deelnemers. We geloven dat het opbouwen van een veerkrachtig pensioen een combinatie is van
                    inzicht, innovatie en toewijding, en dat is precies wat we bieden.
                    <br />
                    <br />
                    Ons pensioenfonds is geboren uit een diepgewortelde wens om een verschil te maken in het leven
                    van mensen. We streven ernaar om meer dan alleen financiële zekerheid te bieden; we streven
                    ernaar om gemoedsrust te schenken. Bij SereneFuture hanteren we een holistische benadering van
                    pensioenplanning, waarbij we rekening houden met zowel de financiële doelen als de
                    levensstijlverwachtingen van onze deelnemers.
                </p>
                <ButtonView text="Lees verder" background="bright" @click="buttonClicked" />
            </div>
        </div>

        <img src="@/assets/corner.png" class="folded-corner" />
    </div>
</template>

<script>
import ButtonView from '@/components/ButtonView.vue';

export default {
    name: 'HomeBlockPhotos',
    components: {
        ButtonView,
    },
    methods: {
        buttonClicked() {
            this.$router.push('/over-ons');
        },
    },
};
</script>

<style scoped>
.folded-corner {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100px;
}

.folded-corner-parent {
    position: relative;
}

.container-section {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-top: 50px;
    padding-bottom: 12.5px;
}

.img-content-dbf415fc {
    content: url("@/assets/front_6.jpg");
}

.img-content-142be835 {
    content: url("@/assets/front_7.jpg");
}

.img-content-8a201692 {
    content: url("@/assets/front_2.jpg");
    height: 258px;
    border: 0.8rem solid rgb(251, 250, 249) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    transform: rotate(-6deg) scale(1.15) translate(-0.4rem, 0);
}

.container-subsection {
    box-sizing: border-box;
    margin-top: 30px;
}

.image-container {
    box-sizing: border-box;
    display: block;
    width: 200px;
    max-width: initial;
    border: none;
    object-fit: cover;
}

.future-container {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-top: 80px;
    padding-bottom: 72px;
}

.serene-future {
    box-sizing: border-box;
    flex: 0 1 auto;
    align-self: flex-start;
}

.serene-future2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: flex-end;
    max-width: 1170px;
    padding: 30px 71px 39px;
    background: #f6f9ff;
    align-items: flex-start;
}

.serene-future-text {
    box-sizing: border-box;
    margin-top: 12px;
    font: 300 16px/25px Roboto;
    color: black;
}
</style>
