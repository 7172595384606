<template>
    <div class="row">
        <div class="investment-section">
            <p class="caption">Onze investeringen</p>
            <div class="article-container1">
                <div class="container-with-image">
                    <div class="article-container">
                        <p class="hero-title">
                            Afgeronde
                            <br />
                            Projecten
                        </p>
                        <ButtonView @click="buttonClicked" background="bright" text="Lees verder" />
                    </div>
                    <div class="content-wrapper">
                        <img class="image-container5 img-content-9261cda8" />
                    </div>
                </div>
                <div class="content-section">
                    <div class="flexbox-container">
                        <img class="image-container2 img-content-142be835" />
                    </div>
                    <div class="image-container3">
                        <img class="image-container img-content-87d2b90e" />
                    </div>
                    <div class="image-container4">
                        <img class="image-container1 img-content-8fa07e13" />
                    </div>
                </div>

                <ButtonView @click="buttonClicked" background="dark" text="Lees verder" />
            </div>
        </div>

    </div>
</template>

<script>
import ButtonView from '@/components/ButtonView.vue';

export default {
    name: 'HomeBlockProjects',
    components: {
        ButtonView,
    },
    methods: {
        buttonClicked() {
            this.$router.push('/beleggingen');
        },
    },
};
</script>

<style scoped>
.investment-section {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    min-width: 73.125rem;
    background: white;
}

.article-container1 {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 1.875rem;
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: 3.75rem;
}

.container-with-image {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    justify-content: flex-start;
}

.article-container {
    box-sizing: border-box;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 58px 4.9375rem 2.9375rem;
}

.hero-title {
    box-sizing: border-box;
    flex: 0 0 auto;
    max-width: 18.875rem;
    font: 700 4rem/4rem Roboto;
    color: white;
}

.flex-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.5rem;
}

.button-read-more {
    box-sizing: border-box;
    flex: 0 0 auto;
    font: 400 0.75rem Gothic A1;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.20000000000000004em;
}

.svg-container1 {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.875rem;
    color: #ffffff;
}

.content-wrapper {
    box-sizing: border-box;
    flex: 0 0 auto;
    margin-left: 1.875rem;
}

.image-container5 {
    box-sizing: border-box;
    display: block;
    width: 35.625rem;
    max-width: initial;
    border: none;
}

.content-section {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 1.875rem;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
}

.flexbox-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.image-container2 {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    width: 16.875rem;
    max-width: initial;
    height: 15.9375rem;
    border: none;
}

.image-container3 {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.image-container {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    width: 29.375rem;
    max-width: initial;
    height: 15.9375rem;
    border: none;
}

.image-container4 {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.image-container1 {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    width: 23.125rem;
    max-width: initial;
    height: 15.9375rem;
    border: none;
}

.img-content-8fa07e13 {
    content: url("@/assets/postcard.png");
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(5deg) scale(1.05);
    /* border: 0.5rem solid white; */
    /* box-shadow: 0 0 10px rgba(0,0,0,0.2); */
    /* border-radius: 0.7rem; */
    /* background: white; */
}

.img-content-87d2b90e {
    content: url("@/assets/front_3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.img-content-142be835 {
    content: url("@/assets/front_7.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.img-content-9261cda8 {
    content: url("@/assets/front_4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.article-container {
    background: url("@/assets/front_6.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}
</style>
