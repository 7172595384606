<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light header" id="content">
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex justify-content-between align-items-center">
          <a class="navbar-brand" href="/">
            <img src="@/assets/logo.png" alt="SereneFuture Logo" class="logo">
          </a>
          <div class="navbar ml-auto">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <router-link to="/over-ons" class="nav-link">Over Ons</router-link>
              </li>
              <li class="nav-item">
                <router-link v-if="isAdmin && sessionToken" to="/admin" class="nav-link">Administratie</router-link>
                <router-link v-else-if="sessionToken" to="/control-panel" class="nav-link">Dashboard</router-link>
                <router-link v-else to="/login" class="nav-link">Inloggen</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </nav>
</template>
  
<script>
import { mapState } from 'vuex';

export default {
  name: 'HeaderView',
  computed: {
    ...mapState(['sessionToken', 'isAdmin']) // Maps the sessionToken state to a computed property
  }
};
</script>
  
<style scoped>
.header {
  position: relative;
  /* Set to relative so that the logo can be positioned absolutely in relation to this */
  padding: 10px 20px;
  /* Adjust padding as necessary */
}

.logo {
  width: 140px;
  height: auto;
}

.navbar-brand {
  position: absolute;
  top: -10px;
  /* Adjust as needed to get the desired overlap */
  left: 20px;
  z-index: 10;
}

.navbar,
.header .row {
  width: 100%;
}

.nav-link {
  --font-size: 1rem;
  color: var(--color-blue);
  font-family: Roboto;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-size);
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  display: block;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  margin: 1rem;
}

.nav-link:hover,
.router-link-exact-active {
  border-top: 1px solid var(--color-blue-light);
  border-bottom: 1px solid var(--color-blue-light);
}
</style>
  