<template>
    <button v-if="tooLate || done" class="admin-button disabled" @click="onClick">
        {{ text }}
    </button>
    <button v-else class="admin-button" @click="onClick">
        {{ text }}
    </button>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        done: {
            type: Boolean,
            default: false
        },
        tooLate: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClick() {
            if (this.done) {
                console.error('This task is already done');
                return;
            } else if (this.tooLate) {
                console.error('This task is too late');
                return;
            }

            // You can emit an event or perform an action here when the button is clicked
            this.$emit('click');
        }
    }
}
</script>
  
<style scoped>
.admin-button {
    width: 21.5rem;
    height: 5.8125rem;
    margin-top: 1.1875rem;
    font: 800 1.78125rem Roboto;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    background: #5f8bff;
    border: 0.1875rem solid #5f8bff;
    border-radius: 1.125rem;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
}

.disabled {
    cursor: not-allowed;
    background-color: lightgray;
    border-color: lightgray;
}
</style>