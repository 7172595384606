const states_one = require('./variant_one');
const states_two = require('./variant_two');
const states_three = require('./variant_three');

const variants = [null, null, null];

variants[0] = {
    metersVisibleAfter: 9,
    fifthMeterVisibleAfter: 9,
    phonebookVisibleAfter: 0,
    states: states_one.states_one,
    min: [-10, -7, -9, -4, -5],
    max: [9, 4, 3, 4, 4],
};
variants[1] = {
    metersVisibleAfter: 9,
    fifthMeterVisibleAfter: 9,
    phonebookVisibleAfter: 0,
    states: states_two.states_two,
    min: [-14, -8, -11, -7, -8],
    max: [12, 5, 5, 2, 5],
};
variants[2] = {
    metersVisibleAfter: 9,
    fifthMeterVisibleAfter: 9,
    phonebookVisibleAfter: 0,
    states: states_three.states_three,
    min: [-20, -10, -14, -9, -10],
    max: [18, 4, 5, 2, 4],
};

module.exports = {
    getVariant(variant) {
        var ix = variant || 0;
        if (ix < 0 || ix >= variants.length) {
            ix = 0;
        }
        return variants[ix];
    },
    progressStates(variant) {
        return this.getVariant(variant).states;
    },
    /**
     * Finds the progress of a dilemma in a given variant.
     *
     * @param {type} variant - the game variant to search in
     * @param {type} dilemmaId - the ID of the dilemma to find the progress of
     * @return {type} the index of the dilemma in the variant, or -1 if not found
     */
    findDilemmaProgress(variant, dilemmaId) {
        const states = this.getVariant(variant).states;
        // Find index of dilemmaId in states
        const progress = states.findIndex((e) => {
            return e.dilemmaId == dilemmaId;
        });
        //console.log(`findDilemmaProgress(${variant}, ${dilemmaId}): ${progress}`);
        return progress;
    },
    findDilemma(variant, dilemmaId) {
        const states = this.getVariant(variant).states;
        // Find index of dilemmaId in states
        const progress = states.findIndex((e) => {
            return e.dilemmaId == dilemmaId;
        });
        return states[progress];
    },
    findNextDilemma(variant, dilemmaId) {
        const states = this.getVariant(variant).states;
        const currentIndex = this.findDilemmaProgress(variant, dilemmaId);
        if (currentIndex < states.length - 1) {
            return states[currentIndex + 1];
        } else {
            console.log('No next dilemma');
            return states[currentIndex];
        }
    },
    getDilemmaId(variant, progress) {
        const state = this.getVariant(variant).states[progress];
        return state.dilemmaId;
    },
    areMetersVisible(variant, progress) {
        const state = this.getVariant(variant).states[progress];
        if (!state) {
            console.log(`No state found for variant ${variant} and progress ${progress}`);
            return false;
        }
        return true; //state.areMetersVisible || false;
    },
    isFifthMeterVisible(variant, progress) {
        // TODO determine when this meter is visible
        return this.areMetersVisible(variant, progress); // progress >= this.getVariant(variant).fifthMeterVisibleAfter;
    },
    // isPhonebookVisible(variant, progress) {
    //     return progress > this.getVariant(variant).phonebookVisibleAfter;
    // },
    // isHacked(variant, progress) {
    //     return false; // progress >= 12;
    // },
    isFinished(variant, progress) {
        return progress >= this.getVariant(variant).states.length;
    },
    isLogoutVisible() {
        return true;
    }
    // etc.
};
