<template>
    <br />
    Developer tools - <strong>Niet voor spelers!</strong><br />
    [<a href="#" @click.prevent="logout">Uitloggen</a>]
    [<a href="#" @click.prevent="reset">Spel resetten</a>]
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'ControlPanelView',
    methods: {
        ...mapActions(['logoutUser', 'resetGamestate']),
        logout() {
            if (window.confirm('Weet u zeker dat u wilt uitloggen?')) {
                this.logoutUser();
                this.$router.push('/');
            }
        },
        reset() {
            this.resetGamestate();
        }
    }
}
</script>