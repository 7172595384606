<template>
    <h1 class="control-panel-title">Sessies</h1>
    <div class="control-panel-content">
        <table class="table custom-table">
            <thead>
                <th>Admin code</th>
                <th>Aangemaakt op</th>
            </thead>
            <tbody>
                <tr v-for="session in sessions" :key="session.id">
                    <!-- <td>{{ session.id }}</td> -->
                    <!-- <td>{{ session.sessionToken }}</td> -->
                    <td>
                        <router-link :to="'/admin/sessions/' + session.adminCode">{{ session.adminCode }}</router-link>
                    </td>
                    <td>{{ formatDate(session.createdAt) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import api from '@/repository/api.js'

export default {
    data() {
        return {
            sessions: [],
        };
    },
    async created() {
        this.fetchSessions();
    },
    methods: {
        async fetchSessions() {
            try {
                const token = this.$store.state.sessionToken;
                const response = await api.fetchAllSessions(token);
                this.sessions = response.data.data;
            } catch (error) {
                console.error("An error occurred while fetching data: ", error);
            }
        },
        formatDate(timestamp) {
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const date = new Date(timestamp);

            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');

            return `${day} ${month} ${year}, ${hours}:${minutes}`;
        }

    }
}
</script>

<style scoped>
.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table th,
.custom-table td {
    text-align: left;
    padding: 8px;
}

tbody tr:nth-child(odd) {
    background-color: white;
}
</style>
