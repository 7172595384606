<template>
    <HeaderView />

    <div class="container-fluid">

        <!-- <BannerView v-if="showBannerKcc" /> -->

        <BannerView v-if="showBannerPortal" />

        <CarouselView />

        <NewsView v-if="showNews" :news="news" />

        <div class="container mt-5">
            <HomeBlockPhotos />
            <br />
            <HomeBlockMission />
            <br />
            <HomeBlockProjects />
            <br /><br />
        </div>
    </div>

    <FooterView />
</template>

<script>
import HeaderView from '@/components/HeaderView.vue';
import BannerView from '@/components/BannerView.vue';
import CarouselView from '@/components/CarouselView.vue';
import NewsView from '@/components/NewsView.vue';
import HomeBlockPhotos from '@/components/HomeBlockPhotos.vue';
import HomeBlockMission from '@/components/HomeBlockMission.vue';
import HomeBlockProjects from '@/components/HomeBlockProjects.vue';
import FooterView from '@/components/FooterView.vue';

export default {
    name: 'HomeView',
    components: {
        HeaderView,
        BannerView,
        CarouselView,
        NewsView,
        HomeBlockPhotos,
        HomeBlockMission,
        HomeBlockProjects,
        FooterView
    },
    data() {
        return {
            showBannerKcc: false,
            showBannerPortal: false,
            showNews: false,
        }
    },
    mounted() {
        if (this.$store.state) {
            // Only if gamestate dictates that the banner should be shown
            this.showBannerKcc = !this.$store.state.gamestate.kccEnabled;
            this.showBannerPortal = !this.$store.state.gamestate.portalEnabled;

            const newsIndex = this.$store.state.gamestate.websiteMessages.findIndex(item => item.visible);
            if (newsIndex >= 0) {
                this.news = this.$store.state.gamestate.websiteMessages[newsIndex];
                this.showNews = true;
            }
        }
    }
};
</script>

<style scoped></style>
