<template>
    <h1 class="control-panel-title">Meters</h1>
    <div class="control-panel-content">
        <DialView :value="session.valueMeter1" :min="32" :max="68" class="dial" title="Mening<br />Toezichthouders" />

        <DialView :value="session.valueMeter2" :min="38" :max="62" class="dial" title="Mening<br />Deelnemers" />

        <DialView :value="session.valueMeter3" :min="36" :max="58" class="dial" title="Reputatie<br />Media" />

        <DialView :value="session.valueMeter4" :min="44" :max="55" class="dial" title="Mening<br />Werkgevers" />

        <DialView :value="session.valueMeter5" :min="40" :max="56" class="dial" title="Kwaliteit<br />Dienstverlening"
            v-if="isVisible" />

        <div class="footnote">
            Bron: Centraal Paniekbureau voor Statistiek.
        </div>
    </div>
</template>

<script>
import DialView from "@/components/controlpanel/DialView.vue"
import gametree from "@/repository/gametree"

export default {
    name: "ControlPanelMeters",
    data() {
        return {
            isVisible: false,
            min1: 32,
            max1: 68,
            min2: 38,
            max2: 62,
            min3: 36,
            max3: 58,
            min4: 44,
            max4: 55,
            min5: 40,
            max5: 56
        }
    },

    mounted() {
        this.isVisible = gametree.isFifthMeterVisible(this.session.variant, this.session.progress);
        let mins = gametree.getVariant(this.session.variant).min;
        let maxs = gametree.getVariant(this.session.variant).max;
        this.min1 = 50 - mins[0];
        this.max1 = 50 + maxs[0];
        this.min2 = 50 - mins[1];
        this.max2 = 50 + maxs[1];
        this.min3 = 50 - mins[2];
        this.max3 = 50 + maxs[2];
        this.min4 = 50 - mins[3];
        this.max4 = 50 + maxs[3];
        this.min5 = 50 - mins[4];
        this.max5 = 50 + maxs[4];
    },

    computed: {
        session() {
            if (!this.$store.state.sessionToken) {
                return -1;
            }
            return this.$store.state.session;
        }
    },

    watch: {
        session() {
            this.isVisible = gametree.isFifthMeterVisible(this.session.variant, this.session.progress);
        }
    },
    components: {
        DialView
    }
}
</script>

<style scoped>
.dial {
    display: inline-block;
}

.footnote {
    font-size: 9pt;
}

.control-panel-content {
    text-align: center;
}
</style>
