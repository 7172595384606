<template>
  <div class="container banner">
    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center">
        Wegens een technische storing is Mijn SereneFuture momenteel niet toegankelijk. Wij zijn hard bezig aan een
        oplossing.
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'BannerView',
};
</script>
  
<style scoped>
.banner {
  background: rgb(248, 152, 152);
  padding: 4rem 2rem 2rem 3rem;
  color: #660606;
  font-weight: 500;
}
</style>
  