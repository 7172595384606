
<template>
    <div class="control-panel-content">
        <table class="table custom-table">
            <thead>
                <th></th>
                <th>Event / Dilemma</th>
                <th>Antwoord</th>
                <th>Resultaat</th>
            </thead>
            <tbody>
                <tr v-for="log in logs" :key="log.id">
                    <td class="timestamp">{{ formatDatetime(log.loggedAt) }}</td>
                    <td>{{ log.message }}</td>
                    <td>{{ log.answer }}</td>
                    <td>{{ calculateEffect(log) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import api from '@/repository/api.js'
import datetimeutils from '@/utils/datetimeutils.js'

export default {
    data() {
        return {
            logs: []
        };
    },
    async mounted() {
        this.refresh();

        // Set up an interval to fetch sessions every 10 seconds
        this.intervalId = setInterval(this.refresh, 10000);
    },
    beforeUnmount() {
        clearInterval(this.intervalId);
    },
    methods: {
        refresh: async function () {
            const adminCode = this.$route.params.adminCode;
            const token = this.$store.state.sessionToken;
            //console.log(`Fetching data for adminCode: ${adminCode}`);
            this.fetchLogs(token, adminCode);
        },
        fetchLogs: async function (token, adminCode) {
            try {
                const response = await api.fetchLogs(token, adminCode);
                this.logs = response.data.data;
            } catch (error) {
                console.error("Error fetching logs: ", error);
            }
        },
        formatDatetime(timestamp) {
            return datetimeutils.formatTime(timestamp);
        },
        calculateEffect(log) {
            var effect = [];
            if (log.valueMeter1Before != log.valueMeter1After) {
                const diff = log.valueMeter1After - log.valueMeter1Before;
                if (diff < 0) {
                    effect.push("T: " + diff);
                } else {
                    effect.push("T: +" + diff);
                }
            }
            if (log.valueMeter2Before != log.valueMeter2After) {
                const diff = log.valueMeter2After - log.valueMeter2Before;
                if (diff < 0) {
                    effect.push("D: " + diff);
                } else {
                    effect.push("D: +" + diff);
                }
            }
            if (log.valueMeter3Before != log.valueMeter3After) {
                const diff = log.valueMeter3After - log.valueMeter3Before;
                if (diff < 0) {
                    effect.push("RM: " + diff);
                } else {
                    effect.push("RM: +" + diff);
                }
            }
            if (log.valueMeter4Before != log.valueMeter4After) {
                const diff = log.valueMeter4After - log.valueMeter4Before;
                if (diff < 0) {
                    effect.push("W: " + diff);
                } else {
                    effect.push("W: +" + diff);
                }
            }
            if (log.valueMeter5Before != log.valueMeter5After) {
                const diff = log.valueMeter5After - log.valueMeter5Before;
                if (diff < 0) {
                    effect.push("KD: " + diff);
                } else {
                    effect.push("KD: +" + diff);
                }
            }

            return effect.join(", ");
        }
    }
}
</script>

<style scoped>
.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table th,
.custom-table td {
    text-align: left;
    padding: 8px;
    font-size: 14pt;
}

tbody tr:nth-child(odd) {
    background-color: white;
}

td.timestamp {
    font-size: 12pt;
    color: grey;
    line-height: 20pt;
}
</style>
