import { createApp } from 'vue';
import App from './App.vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { BootstrapVue } from 'bootstrap-vue';

import '@/assets/styles/global.css';
import router from './router.js';
import store from './store.js';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas, far);
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
//library.add(faUserSecret)

const app = createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon);
app.use(BootstrapVue)
app.use(router)
app.use(store)
app.mount('#app');
