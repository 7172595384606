<template>
    <div class="control-panel row">

        <AdminSidebar />

        <div class="content col-8">
            <div class="logout"><a href="#" @click.prevent="logout">Uitloggen</a></div>
            <router-view></router-view>
        </div>
    </div>
</template>
  
<script>
import { mapActions } from 'vuex';
import AdminSidebar from '@/components/admin/AdminSidebar.vue';

export default {
    name: 'AdminView',
    components: { AdminSidebar },
    methods: {
        ...mapActions(['logoutUser']),
        logout() {
            if (window.confirm('Weet u zeker dat u wilt uitloggen?')) {
                this.logoutUser();
                this.$router.push('/');
            }
        }
    }
}
</script>
  
<style>
.control-panel {
    display: flex;
}

.logout a {
    position: absolute;
    right: 0;
    font-size: 12pt;
    font-weight: 600;
    padding: 0.5em;
}
</style>
