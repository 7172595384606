<template>
    <h1 class="control-panel-title" id="content">Telefoonboek</h1>
    <div class="control-panel-content container-fluid">
        <table class="table">
            <thead>
                <th>Naam</th>
                <th>Telefoonnummer</th>
            </thead>
            <tbody>
                <tr v-for="phone in phonebook" :key="phone.name">
                    <td class="phone-name">{{ phone.name }}</td>
                    <td class="phone-number">{{ phone.number }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <h1 class="control-panel-title">Voicemails</h1>

    <ControlPanelVoicemail />
</template>

<script>
import ControlPanelVoicemail from "@/components/controlpanel/ControlPanelVoicemail";

export default {
    name: "ControlPanelPhone",
    components: {
        ControlPanelVoicemail
    },
    data() {
        return {
            phonebook: []
        }
    },
    mounted() {
        // In the latest update, we decide to hide the dynamic phone number
        const showDynamicNumbers = false;
        let items = [];
        if (this.$store.state.gamestate && this.$store.state.gamestate.phonebook) {
            this.$store.state.gamestate.phonebook.forEach(item => {
                if (item.visible) {
                    if (item.isDynamic) {
                        item.number = this.$store.state.session.phoneNumber1;
                        if (showDynamicNumbers) {
                            items.push(item);
                        }
                    } else {
                        items.push(item);
                    }
                }
            });
        }
        this.phonebook = items;
    }
}
</script>

<style scoped>
.phone-number {
    font-weight: 700;
    font-family: monospace;
    font-size: 1.8rem;
}

.table td {
    border-top: none;
}
</style>
