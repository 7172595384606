<template>
    <HeaderView />

    <div class="container-fluid">

        <BannerView v-if="showBanner" />

        <div class="container mt-5">

            <div class="row">
                <div class="mission-container">
                    <p class="caption">Privacybeleid</p>
                </div>
            </div>
        </div>

        <div class="container mt-5">
            <div class="row">
                <div class="col-12">
                    <h3>1. Inleiding</h3>
                    <p>Bij SereneFuture respecteren we uw privacy en streven we ernaar om uw persoonlijke gegevens op een
                        transparante, vertrouwelijke en veilige manier te verwerken. Dit privacybeleid biedt informatie over
                        hoe
                        wij uw persoonlijke gegevens verzamelen, gebruiken, delen en beschermen.</p>
                    <h3>2. Verzamelde Gegevens</h3>
                    <p>We verzamelen informatie die u direct aan ons verstrekt, zoals uw naam, adres, e-mailadres en andere
                        persoonlijke identificatie-informatie. Daarnaast verzamelen we automatisch informatie wanneer u onze
                        website bezoekt, zoals uw IP-adres, browsertype en -versie, en pagina-interactie-informatie.</p>
                    <h3>3. Gebruik van Gegevens</h3>
                    <p>Uw gegevens worden gebruikt om:</p>
                    <ul>
                        <li>Onze diensten te leveren en verbeteren.</li>
                        <li>U te informeren over relevante producten en updates.</li>
                        <li>Klantenservice en ondersteuning te bieden.</li>
                        <li>Interne analyses en onderzoek uit te voeren.</li>
                        <li>Wettelijke en regelgevende verplichtingen na te komen.</li>
                    </ul>
                    <h3>4. Delen van Gegevens</h3>
                    <p>We verkopen uw gegevens niet en delen deze alleen met derden wanneer dit nodig is voor onze
                        dienstverlening of wanneer de wet dit vereist.</p>
                    <h3>5. Bewaren van Gegevens</h3>
                    <p>Uw gegevens worden bewaard zolang als nodig is om de diensten te leveren die u heeft aangevraagd, of
                        voor
                        andere essentiële doeleinden zoals het voldoen aan onze wettelijke verplichtingen.</p>
                    <h3>6. Beveiliging</h3>
                    <p>We hebben uitgebreide maatregelen getroffen om uw gegevens te beschermen tegen onbevoegde toegang,
                        gebruik, verlies of openbaarmaking. Deze maatregelen omvatten fysieke, technologische en
                        organisatorische maatregelen.</p>
                    <h3>7. Uw Rechten</h3>
                    <p>U heeft het recht op inzage, correctie, verwijdering en bezwaar tegen de verwerking van uw
                        persoonlijke
                        gegevens. Neem contact met ons op om deze rechten uit te oefenen.</p>
                    <h3>8. Cookies</h3>
                    <p>Onze website maakt gebruik van cookies om de functionaliteit te verbeteren en ons te voorzien van
                        informatie over hoe de website wordt gebruikt. Raadpleeg ons Cookiebeleid voor meer informatie.</p>
                    <h3>9. Wijzigingen in dit Beleid</h3>
                    <p>Dit privacybeleid kan van tijd tot tijd worden bijgewerkt. Bekijk het privacybeleid van tijd tot tijd
                        op
                        de laatste versie te zien.</p>

                    <h3>10. Contact</h3>
                    <p>Voor vragen of zorgen over dit privacybeleid kunt u contact met ons opnemen via:</p>
                    <p>SereneFuture<br />
                        Verbeeldingslaan 123<br />
                        4567 AB Utopiastad<br />
                        Nederland<br />
                        E-mail: privacy@serenefuture.nl<br />
                    </p>
                    <p> Dit privacybeleid is voor het laatst bijgewerkt op 1 september 2023.</p>
                </div>
            </div>
        </div>

        <!-- <div class="header-image image-4" style="margin-top:3rem;"></div> -->
    </div>

    <FooterView />
</template>

<script>
import HeaderView from '@/components/HeaderView.vue';
import BannerView from '@/components/BannerView.vue';
import FooterView from '@/components/FooterView.vue';

export default {
    name: 'InfoPrivacy',
    components: {
        HeaderView, BannerView, FooterView
    }
};
</script>

<style scoped>
.container-fluid {
    margin-bottom: 3em;
}

.mission-container {
    box-sizing: border-box;
    max-width: 73.125rem;
    padding-bottom: 1.3125rem;
    background: white;
}

.serene-future-mission {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 3.75rem;
}

.serene-future-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.journey-guide {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 1.75rem;
    margin-left: 2.9375rem;
}

.serene-future-text {
    box-sizing: border-box;
    flex: 0 1 auto;
    margin-left: 1.875rem;
}

.journey-guide1 {
    box-sizing: border-box;
    flex: 0 1 auto;
    margin-left: -1.1875rem;
}

.signature {
    font-size: 15pt;
    display: block;
    margin-top: 2rem;
}

.header-image {
    width: 100%;
    height: 11em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.image-1 {
    background-image: url(@/assets/carousel_2.jpg);
}

.image-2 {
    background-image: url(@/assets/about-us.jpg);
}

.image-3 {
    background-image: url(@/assets/office-with-logo.jpg);
}

.image-4 {
    background-image: url(@/assets/about-us-3.jpg);
}

.news-container {
    background: #f6f9ff;
    padding: 2.5em;
    margin-top: 2em;
    margin-bottom: 2em;
}

.news-container p {
    text-align: justify;
}

.news-container h2 {
    color: #444;
    font-weight: normal;
    margin-bottom: 0.5em;
    font-size: 1.9rem;
}
</style>