<template>
    <HeaderView />

    <div class="container-fluid">

        <BannerView v-if="showBanner" />

        <div class="container mt-5">

            <div class="row">
                <div class="mission-container">
                    <p class="caption">Over Ons</p>
                    <div class="serene-future-mission">
                        <div class="serene-future-container">
                            <p class="serene-future-text">Iedereen droomt van een comfortabele en zorgeloze oude dag. Of het
                                nu gaat om genieten van uw tuin, regelmatige uitstapjes of simpelweg tijd doorbrengen met
                                dierbaren, bij SereneFuture zorgen we ervoor dat uw dromen werkelijkheid worden.</p>
                        </div>
                        <div class="journey-guide">
                            <p class="journey-guide1">De horizon van uw welverdiende pensioen nadert. Dit zijn de momenten
                                om vooruit te kijken en te plannen voor die dagen vol vrijheid, plezier en comfort.
                                SereneFuture begeleidt u op deze belangrijke overgang, zodat u vol vertrouwen uw nieuwe
                                levensfase tegemoet kunt treden.

                                <span class="signature">Penn-Sean, Fons (CEO)</span>
                                <img src="@/assets/signature.png" width="240" />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="header-image image-1"></div>

        <div class="container mt-5">
            <div class="row">
                <div class="news-container">
                    <p>Het moment is aangebroken: u bent met pensioen! Een tijd van nieuwe mogelijkheden, vrijheid en het
                        koesteren van elk moment. Bij SereneFuture begrijpen we dat dit een speciale fase is. We staan aan
                        uw
                        zijde om ervoor te zorgen dat u elke dag ten volle kunt leven.</p>
                    <ul>
                        <li>
                            <strong>Het aftellen is begonnen</strong>
                            <p>
                                Nog maar een paar stappen verwijderd van uw welverdiende rust. Wij zorgen ervoor dat alles
                                vlekkeloos verloopt.
                            </p>
                        </li>
                        <li>
                            <strong>Genieten Zonder Zorgen.</strong>
                            <p>Na jaren van toewijding is het nu tijd voor u. Met SereneFuture aan uw zijde wordt uw
                                pensioen
                                zorgeloos
                                en plezierig.</p>
                        </li>
                        <li>
                            <strong>Uw Dromen in Zicht.</strong>
                            <p>Of het nu gaat om meer tijd met de kleinkinderen, een nieuwe hobby of simpelweg genieten van
                                de
                                kleine
                                dingen, wij staan klaar om u te ondersteunen.</p>
                        </li>
                        <li>
                            <strong>Geruststellend Overzicht.</strong>
                            <p>Een duidelijk beeld van uw financiën betekent een heldere kijk op de toekomst. SereneFuture
                                biedt u
                                inzicht en gemoedsrust.</p>
                        </li>
                        <li>
                            <strong>Voorbereid op het Onverwachte.</strong>
                            <p>Het leven zit vol verrassingen. Wij zorgen ervoor dat u voorbereid bent op alle mogelijkheden
                                die uw
                                pensioen met zich meebrengt.</p>
                        </li>
                        <li>
                            <strong>Elke Dag, Een Nieuw Avontuur.</strong>
                            <p>Begin aan uw volgende hoofdstuk met enthousiasme. Wij staan achter u, elke stap van de weg.
                            </p>
                        </li>
                    </ul>
                    <p>
                        SereneFuture is uw partner op weg naar de zorgeloze en comfortabele oude dag waar u altijd van heeft
                        gedroomd. Maak vandaag nog de stap naar een zonnige toekomst.</p>

                    <p class="align-right">
                        <ButtonView text="De Toekomst van Pensioen" background="dark" @click="buttonClicked"
                            class="btn-lees-meer" />
                    </p>
                </div>
            </div>
        </div>

        <div class="header-image image-2" style="margin-top:3rem;"></div>
    </div>

    <FooterView />
</template>

<script>
import HeaderView from '@/components/HeaderView.vue';
import BannerView from '@/components/BannerView.vue';
import FooterView from '@/components/FooterView.vue';
import ButtonView from '@/components/ButtonView.vue';

export default {
    name: 'AboutUs',
    components: {
        HeaderView, BannerView, FooterView, ButtonView
    },
    methods: {
        buttonClicked() {
            this.$router.push('/over-ons-2');
        }
    }
};
</script>

<style scoped>
.container-fluid {
    margin-bottom: 3em;
}

.mission-container {
    box-sizing: border-box;
    max-width: 73.125rem;
    padding-bottom: 1.3125rem;
    background: white;
}

.serene-future-mission {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 3.75rem;
}

.serene-future-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.journey-guide {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 1.75rem;
    margin-left: 2.9375rem;
}

.serene-future-text {
    box-sizing: border-box;
    flex: 0 1 auto;
    margin-left: 1.875rem;
}

.journey-guide1 {
    box-sizing: border-box;
    flex: 0 1 auto;
    margin-left: -1.1875rem;
}

.signature {
    font-size: 15pt;
    display: block;
    margin-top: 2rem;
}

.header-image {
    width: 100%;
    height: 11em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.image-1 {
    background-image: url(@/assets/carousel_2.jpg);
}

.image-2 {
    background-image: url(@/assets/about-us.jpg);
}

.news-container {
    background: #f6f9ff;
    padding: 2.5em;
}

.news-container p {
    text-align: justify;
}

.news-container h2 {
    color: #444;
    font-weight: normal;
    margin-bottom: 0.5em;
    font-size: 1.9rem;
}

.news-container p.align-right {
    text-align: right;
}
</style>