<template>
    <button class="button-with-icon" :class="background" @click="onClick">
     {{ text }}
    <div class="svg-container">
        <svg viewBox="0 0 24 24" x="0" y="0" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="arrow-2-right-long_5" xmlns="http://www.w3.org/2000/svg">
            <g id="ico-shape_5">
            <path id="Vector (Stroke)_5" fill-rule="evenodd" clip-rule="evenodd" d="M3.291,12c0,-0.28 0.22,-0.5 0.5,-0.5h16c0.28,0 0.5,0.22 0.5,0.5c0,0.28 -0.22,0.5 -0.5,0.5h-16c-0.28,0 -0.5,-0.22 -0.5,-0.5z" fill="currentColor" />
            <path id="Vector 2 (Stroke)_5" fill-rule="evenodd" clip-rule="evenodd" d="M15.941,8.15c0.19,-0.2 0.51,-0.2 0.7,0l3.86,3.85l-3.86,3.85c-0.19,0.2 -0.51,0.2 -0.7,0c-0.2,-0.19 -0.2,-0.51 0,-0.7l3.14,-3.15l-3.14,-3.15c-0.2,-0.19 -0.2,-0.51 0,-0.7z" fill="currentColor" />
            </g>
        </g>
        </svg>
    </div>
    </button>
</template>
  
<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        background: {
            type: String,
            required: true,
            validator: value => ['dark', 'bright'].includes(value)
        }
    },
    methods: {
        onClick() {
            // You can emit an event or perform an action here when the button is clicked
            this.$emit('click');
        }
    }
}
</script>
  
<style scoped>
.button-with-icon {
    cursor: pointer;
    transition: all 0.3s;
}
/* Add some hover effect if you like */
.button-with-icon:hover {
    opacity: 0.8;
}


.dark {
    background-color: #333;
    color: #fff;
}

.bright {
    background-color: #fff;
    color: #333;
}

.svg-container {
   display: flex;
   width: 1.5rem;
   height: 1.5rem;
}
.dark .svg-container {
   color: #ffffff;
}
.dark .svg-container {
   color: #ffffff;
}
.bright .svg-container {
   color: #333;
}
.read-more-button {
    box-sizing: border-box;
    flex: 0 0 auto;
    font: 400 0.75rem Roboto;
    text-transform: uppercase;
}

.button-with-icon {
   box-sizing: border-box;
   display: inline-flex;
   flex: 0 0 auto;
   gap: 1.25rem;
   align-items: center;
   justify-content: center;
   width: 13.875rem;
   height: 4.4375rem;
   font: 400 0.75rem Roboto;
   text-transform: uppercase;
   letter-spacing: 0.20000000000000004em;
   cursor: pointer;
   border: none;
}


</style>
  