import axios from 'axios';


const BASE_URL =
    process.env.NODE_ENV === 'production'
        ? (window.location.host === 'koutersvdmeer.thisispix.com'
            ? 'https://koutersvdmeer.thisispix.com/api'
            : 'https://www.serenefuture.nl/api') // 'https://ec2-13-53-130-216.eu-north-1.compute.amazonaws.com/api'
        : 'http://localhost:3001/api';

const api = axios.create({
    baseURL: BASE_URL,
});

// Optionally, you can set default headers here
// api.defaults.headers.common['Authorization'] = 'AUTH TOKEN';

const fetchAllSessions = (token) => {
    return api.get('/sessions', {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
};
const createSession = async (token, variant, phoneNumber) => {
    return api.post('/sessions', {
        variant: variant,
        phoneNumber: phoneNumber
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
}
const fetchLogs = async (token, adminCode) => {
    return api.get('/logs/' + adminCode, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
}
const fetchSession = async (token, adminCode) => {
    if (adminCode) {
        return api.get('/sessions/' + adminCode, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
    } else {
        return api.get('/session', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    }
}
const emailOpened = async (token, dilemmaId) => {
    return api.post('/markasread', {
        dilemmaId: dilemmaId
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
};
const emailActioned = async (token, messageId, choice) => {
    return api.post('/logs', {
        message: `Actie gekozen op bericht ${messageId}`,
        choice: choice
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
};

/**
 * Forwards the progress of a session to a specific state.
 * This method is ONLY available to admins.
 * Regular players must call answerDilemma instead.
 *
 * @param {string} token - The access token used for authentication.
 * @param {string} adminCode - The admin code of the session.
 * @param {string} fromStep - The progress state to update from.
 * @param {string} toStep - The progress state to update to.
 * @return {Promise} A Promise that resolves with the response from the API.
 */
const continueToProgressState = async (token, adminCode, fromStep, toStep) => {
    return api.post('/sessions/' + adminCode, {
        fromProgress: fromStep,
        progress: toStep
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
}

const pauseGame = async (token, adminCode) => {
    return api.post('/sessions/' + adminCode + '/pause', {}, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
};

const resumeGame = async (token, adminCode, progress, startRound2) => {
    return api.post('/sessions/' + adminCode + '/resume', {
        progress: progress,
        startRound2: startRound2
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
};

/**
 * Posts the chosen answer to the current progress step, for the current session.
 * The backend returns the updated session (with a new progress step and new dial values)
 *
 * @param {string} token - The JWT token for authentication.
 * @param {number} progress - The progress of the session.
 * @param {string} answer - The answer for the session.
 * @param {boolean} isTooLate - Whether the answer was too late or not. Default is false.
 * @return {Promise} A promise that resolves with the response of the API request.
 */
const answerDilemma = async (token, dilemmaId, progress, answer, isTooLate = false) => {
    return api.post('/answer', {
        progress: progress,
        dilemmaId: dilemmaId,
        answer: answer,
        isTooLate: isTooLate
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
}

/**
 * Adjusts the meters for a given session.
 * This method is ONLY available to admins. Regular players must call answerDilemma instead, and the backend will handle the meter updates.
 *
 * @param {string} token - The authentication token.
 * @param {string} adminCode - The admin code.
 * @param {string} message - The message.
 * @param {string} answer - The answer.
 * @param {number} meter1 - The value for meter 1.
 * @param {number} meter2 - The value for meter 2.
 * @param {number} meter3 - The value for meter 3.
 * @param {number} meter4 - The value for meter 4.
 * @param {number} meter5 - The value for meter 5.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const adjustMeters = async (token, adminCode, message, answer, meter1, meter2, meter3, meter4, meter5) => {
    return api.post('/sessions/' + adminCode, {
        message: message,
        answer: answer,
        valueMeter1: meter1,
        valueMeter2: meter2,
        valueMeter3: meter3,
        valueMeter4: meter4,
        valueMeter5: meter5
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
}


const login = async (adminCode) => {
    return api.post('/login', { "adminCode": adminCode });
}

export default {
    login,
    fetchAllSessions,
    createSession,
    fetchLogs,
    fetchSession,
    emailOpened,
    emailActioned,
    continueToProgressState,
    adjustMeters,
    answerDilemma,
    pauseGame,
    resumeGame
};
