<template>
    <HeaderView />

    <div class="container-fluid">

        <BannerView v-if="showBanner" />

        <div class="container mt-5">

            <div class="row">
                <div class="mission-container">
                    <p class="caption">Onze Beleggingen</p>
                    <p>
                        Het Beleggingsbeleid van SereneFuture: Duurzaamheid & Rendement Hand in Hand
                    </p>
                </div>
            </div>
        </div>

        <div class="header-image image-5"></div>

        <div class="container mt-5">
            <div class="row">
                <p>Bij SereneFuture begrijpen we dat uw pensioen meer is dan alleen geld opzij zetten voor de toekomst.
                    Het gaat om het creëren van een wereld waarin u, uw kinderen en de komende generaties kunnen
                    bloeien. Ons beleggingsbeleid reflecteert precies die visie.</p>
                <div class="news-container">
                    <h2>Duurzaamheid als Kompas</h2>
                    <p>Duurzaamheid is een essentieel aspect van onze investeringsstrategie. We geloven dat het mogelijk is
                        om zowel financieel rendement te behalen als een positieve impact te hebben op de wereld. Daarom
                        kiezen we actief voor investeringen die niet alleen financiële winst opleveren, maar ook bijdragen
                        aan een duurzamere toekomst. Dit betekent investeren in bedrijven die zich inzetten voor het
                        verminderen van hun ecologische voetafdruk, het bevorderen van eerlijke arbeidsomstandigheden en het
                        ondersteunen van gemeenschappen waarin ze actief zijn.</p>
                    <h2>Rendement als Belofte</h2>
                    <p>Hoewel duurzaamheid centraal staat in ons beleid, verliezen we het belang van rendement nooit uit het
                        oog. We begrijpen dat een goed pensioen essentieel is voor uw welzijn en dat van uw geliefden.
                        Daarom hebben we een team van financiële experts die zorgvuldig investeringskansen evalueren en
                        selecteren die een balans bieden tussen duurzaamheid en rendement.</p>
                    <h2>Toewijding aan de SDG's</h2>
                    <p>Onze toewijding aan duurzaamheid gaat hand in hand met de Duurzame Ontwikkelingsdoelen (SDG's) van de
                        Verenigde Naties. Deze 17 doelen bieden een universeel kader voor het aanpakken van de meest urgente
                        uitdagingen van onze tijd, van armoedebestrijding tot klimaatactie. Door ons beleggingsbeleid af te
                        stemmen op deze kernwaarden, zorgen we ervoor dat elke euro die we investeren bijdraagt aan een
                        betere toekomst.</p>

                    <p>
                        Wanneer u bijvoorbeeld naar uw pensioenportefeuille kijkt, ziet u mogelijk investeringen in
                        hernieuwbare energieprojecten, wat overeenkomt met SDG 7 (Betaalbare en schone energie). Of u ziet
                        dat we investeren in bedrijven die gelijke kansen voor vrouwen bevorderen, wat aansluit bij SDG 5
                        (Gendergelijkheid).
                    </p>
                    <h2>Voor Ons en voor de Volgende Generaties</h2>
                    <p>Ons duurzame beleggingsbeleid is een belofte aan u en aan de generaties na ons. We willen niet alleen
                        zorgen voor een comfortabel pensioen voor onze klanten, maar ook voor een wereld waarin hun kinderen
                        en kleinkinderen kunnen leven, leren en groeien.</p>

                </div>
                <p>
                    Bij SereneFuture zien we beleggen niet alleen als een financiële activiteit, maar ook als een kans om de
                    wereld vorm te geven. Door duurzaamheid en rendement te combineren, zorgen we voor zowel uw financiële
                    toekomst als de planeet die we allemaal delen.
                </p>
                <p>
                    Stap vandaag nog over naar SereneFuture en investeer in een toekomst die zowel welvarend als
                    verantwoordelijk is.
                </p>
            </div>
        </div>

        <!-- <div class="header-image image-4" style="margin-top:3rem;"></div> -->
    </div>

    <FooterView />
</template>

<script>
import HeaderView from '@/components/HeaderView.vue';
import BannerView from '@/components/BannerView.vue';
import FooterView from '@/components/FooterView.vue';

export default {
    name: 'InfoBeleggingen',
    components: {
        HeaderView, BannerView, FooterView
    }
};
</script>

<style scoped>
.container-fluid {
    margin-bottom: 3em;
}

.mission-container {
    box-sizing: border-box;
    max-width: 73.125rem;
    padding-bottom: 1.3125rem;
    background: white;
}

.header-image {
    width: 100%;
    height: 11em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.image-1 {
    background-image: url(@/assets/carousel_2.jpg);
}

.image-2 {
    background-image: url(@/assets/about-us.jpg);
}

.image-3 {
    background-image: url(@/assets/office-with-logo.jpg);
}

.image-4 {
    background-image: url(@/assets/about-us-3.jpg);
}

.image-5 {
    background-image: url(@/assets/about-us-2.jpg);
}

.news-container {
    background: #f6f9ff;
    padding: 2.5em;
    margin-top: 2em;
    margin-bottom: 2em;
}

.news-container p {
    text-align: justify;
}

.news-container h2 {
    color: #444;
    font-weight: normal;
    margin-bottom: 0.5em;
    font-size: 1.9rem;
}
</style>