import Vue from 'vue'
import Vuex from 'vuex'
import api from './repository/api.js'
import { game } from './repository/gamestate.js'

Vue.use(Vuex);

var storedState = localStorage.getItem('store');

// Needed to make sure we deep copy the game state into the user's session,
// so we can easily reset back to the fresh start of a new game on logout
let deepClone = function (obj) {
    return JSON.parse(JSON.stringify(obj));
}

const debugSessionStatus = false;

const store = new Vuex.Store({
    state: storedState = storedState ? JSON.parse(storedState) : {
        sessionToken: null,
        isAdmin: false,
        session: {},
        gamestate: deepClone(game),
        // Tracking this separated from the session.startTime, so we can adjust if in case the game leaders skip ahead
        internalStartTime: -1,
    },
    getters: {
        session: state => state.session,
    },
    mutations: {
        clearSessionToken(state) {
            state.sessionToken = null;
        },
        SET_SESSION_TOKEN(state, token) {
            state.sessionToken = token;
        },
        SET_ADMIN(state, isAdmin) {
            state.isAdmin = isAdmin;
        },
        SET_SESSION(state, session) {
            state.session = session;
        },
        SET_GAMESTATE(state, gamestate) {
            state.gamestate = gamestate;
        },
        SET_START_TIME(state, startTime) {
            state.internalStartTime = startTime;
        }
    },
    actions: {
        async loginUser({ commit }, adminCode) {
            try {
                const response = await api.login(adminCode);

                if (response.data.errorCode) {
                    // Reject the promise if there is an error code
                    return Promise.reject(response.data.errorCode);
                }

                if (response.data.isAdmin) {
                    commit('SET_ADMIN', true);
                } else {
                    commit('SET_ADMIN', false);
                }

                commit('SET_SESSION_TOKEN', response.data.sessionToken);
                return response;
            } catch (error) {
                // Reject the promise with the error object
                return Promise.reject(error)
            }
        },
        async logoutUser({ commit }) {
            localStorage.clear();
            storedState = null;
            commit('SET_SESSION_TOKEN', null);
            commit('SET_SESSION', {});
            commit('SET_GAMESTATE', deepClone(game));
            commit('SET_START_TIME', -1);
        },
        async resetGamestate({ commit }) {
            commit('SET_GAMESTATE', deepClone(game));
        },
        setSessionToken({ commit }, token) {
            commit('SET_SESSION_TOKEN', token)
        },
        setStartTime({ commit }, newStartTime) {
            console.log(`Updating start time from ${this.state.internalStartTime ? this.state.internalStartTime : this.state.session.startTime} to ${newStartTime}`);
            commit('SET_START_TIME', newStartTime);
        },
        async setSession({ commit }, session) {
            // Make sure that, if we are polling right when the user logs out,
            // that we don't restore the session that the user is trying to log out of:
            if (!this.state.sessionToken) {
                console.log("Not logged in, not setting session");
                return;
            }

            const oldProgress = this.state.session.progress;
            //const variant = session.variant;

            // Check if any of the steps have a starttime and a duration, and if so, if the duration has passed
            session.steps.forEach(async (step, index, arr) => {
                if (step.startTime && step.duration > 0 && !step.isDone && !step.isTooLate) {
                    const currentTime = new Date().getTime();
                    const startTime = new Date(step.startTime).getTime();
                    const endTime = startTime + step.duration;

                    if (currentTime >= endTime) {
                        // The duration has passed, give the backend the 'too late' POST
                        console.log(`Dilemma ${step.dilemmaId} is overdue. Marking it as too late.`);
                        try {
                            const token = this.state.sessionToken;
                            arr[index].isTooLate = true;

                            await api.answerDilemma(token, step.dilemmaId, oldProgress, 0, true);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }
            });

            commit('SET_SESSION', session);

            if (oldProgress !== session.progress) {
                console.log(`STORE - Old progress: ${oldProgress}, new progress: ${session.progress}`);

                // const gamestate = this.state.gamestate;
                // For each email in the inbox (indexed), check if its .revealAfter is larger or equal to the new progress and mark it as visible
                // gamestate.inbox.forEach(element => {
                //     const revealAtId = element.revealAt;
                //     const revealAtProgress = gametree.findDilemmaProgress(variant, revealAtId);
                //     if (revealAtProgress > -1 && revealAtProgress <= session.progress) {
                //         if (!element.visible) {
                //             // Items marked as 'conditional' don't just appear in order;
                //             // they only appear when previous choices indicate so.
                //             // Only stap-19 is conditional.
                //             if (element.conditional) {
                //                 if (element.dilemmaId == "stap-19") {
                //                     // TODO only enable if ... something was stored in the session earlier?
                //                     element.visible = true;
                //                     element.date = getCurrentTime();
                //                 }
                //             } else {
                //                 element.visible = true;
                //                 element.date = getCurrentTime();
                //             }
                //         }

                //     }
                // });
                // gamestate.voicemails.forEach(element => {
                //     const revealAtId = element.revealAt;
                //     const revealAtProgress = gametree.findDilemmaProgress(variant, revealAtId);
                //     if (revealAtProgress > -1 && revealAtProgress <= session.progress) {
                //         if (!element.visible) {
                //             element.date = getCurrentTime();
                //             element.visible = true;
                //         }
                //     }
                // });

                // If the progress has changed, there might be a dilemma answered in
                // another client joining the same session. So, we check the logs and
                // mark any dilemma we find as 'done':
                // try {
                //     const token = this.state.sessionToken;
                //     const adminCode = this.state.session.adminCode;
                //     const response = await api.fetchLogs(token, adminCode);

                //     if (!response.data.errorCode) {
                //         const logs = response.data.data;
                //         logs.forEach(log => {
                //             gamestate.inbox.forEach(element => {
                //                 if (element.id === log.dilemmaId) {
                //                     element.done = true;
                //                 }
                //             });
                //             gamestate.voicemails.forEach(element => {
                //                 if (element.id === log.dilemmaId) {
                //                     element.done = true;
                //                 }
                //             });
                //         })
                //     }

                //     commit('SET_GAMESTATE', gamestate);
                // } catch (error) {
                //     // If it fails, let's just save the updated gamestate as is
                //     commit('SET_GAMESTATE', gamestate);
                // }
            }
        },
        markAsRead({ commit }, dilemmaId) {
            // const index = this.state.gamestate.inbox.findIndex(email => email.id === messageId);
            // this.state.gamestate.inbox[index].isRead = true;
            // commit('SET_GAMESTATE', this.state.gamestate);
            const index = this.state.session.steps.findIndex(s => s.dilemmaId === dilemmaId);
            this.state.session.steps[index].isRead = true;
            commit('SET_SESSION', this.state.session);
        },
        markAsDone({ commit }, dilemmaId) {
            // We quickly set the local status to 'done', so
            // it updates immediately. In a few seconds, when the sync event
            // happens, we'll get the done status from the backend anyway,
            // but aint nobody got time for that.
            const index = this.state.session.steps.findIndex(s => s.dilemmaId === dilemmaId);
            this.state.session.steps[index].isDone = true;
            commit('SET_SESSION', this.state.session);

            // We want the mail inbox id, not the outbox id. For example, the (only)
            // reply to inbox mail "email4" is "email4", but there are 3 possible answers
            // to inbox mail "email5", e.g. "email5.1".
            // const [mainId] = messageId.split('.');
            // const index = this.state.gamestate.inbox.findIndex(email => email.id === mainId);
            // this.state.gamestate.inbox[index].done = true;
            // commit('SET_GAMESTATE', this.state.gamestate);
        },
        showNews({ commit }, messageId) {
            const index = this.state.gamestate.websiteMessages.findIndex(email => email.id === messageId);
            this.state.gamestate.websiteMessages[index].visible = true;
            commit('SET_GAMESTATE', this.state.gamestate);
        },
        revealPhoneNumbers({ commit }, enable) {
            this.state.gamestate.phonebook.forEach(element => {
                element.visible = enable;
            });
            commit('SET_GAMESTATE', this.state.gamestate);
        },
        enableKcc({ commit }, enabled) {
            this.state.gamestate.kccEnabled = enabled;
            commit('SET_GAMESTATE', this.state.gamestate);
        },
        enablePortal({ commit }, enabled) {
            this.state.gamestate.portalEnabled = enabled;
            commit('SET_GAMESTATE', this.state.gamestate);
        },
        async answerDilemma({ dispatch }, { dilemmaId, progress, answer }) {
            try {
                const token = this.state.sessionToken;
                const response = await api.answerDilemma(token, dilemmaId, progress, answer);
                const session = response.data.data;
                if (debugSessionStatus) {
                    console.log(`New session game progress: ${session.progress}`);
                }

                dispatch('setSession', session);
            } catch (e) {
                console.log(e);
            }
        }
    },
});

store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    localStorage.setItem('store', JSON.stringify(state));
});

export default store;
