<template>
    <HeaderView />

    <div class="container-fluid">

        <BannerView v-if="showBanner" />

        <div class="container mt-5">

            <div class="row">
                <div class="mission-container">
                    <p class="caption">De Toekomst van Pensioen</p>
                    <p>
                        In 2016, toen de digitale revolutie op volle toeren draaide, ontstond SereneFuture: een pionier op
                        het gebied van pensioenopbouw. Het concept was eenvoudig maar baanbrekend. Waarom zouden we
                        vasthouden aan de traditionele, kostbare methoden van pensioenbeheer als de technologie ons de
                        middelen bood voor een efficiëntere, klantgerichtere aanpak? Met deze visie werd SereneFuture
                        geboren, een volledig digitaal pensioenfonds dat innovatie, betrouwbaarheid en degelijkheid
                        belichaamt.
                    </p>
                </div>
            </div>
        </div>

        <div class="header-image image-3"></div>

        <div class="container mt-5">
            <div class="row">
                <div class="news-container">
                    <h2>Innovatie op zijn Best</h2>
                    <p>De traditionele pensioenwereld stond voor een uitdaging. De processen waren verouderd, bureaucratisch
                        en vaak ondoorzichtig voor de klant. SereneFuture zag dit als een kans. Door gebruik te maken van de
                        nieuwste technologieën en digitale tools, hebben we een platform gecreëerd dat eenvoudig te
                        navigeren is en dat onze klanten in staat stelt hun pensioenopbouw volledig te beheren met slechts
                        een paar klikken. Dit betekent directe toegang tot uw gegevens, realtime updates en de mogelijkheid
                        om wijzigingen door te voeren wanneer het u uitkomt.</p>

                    <h2>Betrouwbaarheid voorop</h2>
                    <p>Hoewel innovatie de kern is van SereneFuture, begrijpen we ook dat vertrouwen essentieel is wanneer
                        het gaat om uw toekomstige financiële zekerheid. Vanaf de start in 2016, hebben we ons ingezet om
                        niet alleen een technologisch superieur platform te bieden, maar ook een dat robuust en veilig is.
                        Onze infrastructuur maakt gebruik van de meest geavanceerde beveiligingstechnieken om ervoor te
                        zorgen dat de financiële toekomst van onze klanten altijd beschermd is.</p>

                    <h2>Degelijkheid in elke beslissing</h2>
                    <p>Bij SereneFuture is degelijkheid geen ouderwets concept, maar een belofte. Het is onze plicht om
                        ervoor te zorgen dat uw investeringen veilig, solide en winstgevend zijn. Daarom hebben we een team
                        van financiële experts die constant de markt analyseren, risico's evalueren en strategieën
                        formuleren die in lijn zijn met de doelen en wensen van onze klanten.</p>

                    <h2>Optimale service, minimale kosten</h2>
                    <p>Ons digitale model heeft niet alleen de deur geopend naar een meer toegankelijke pensioenwereld, maar
                        heeft ons ook in staat gesteld om de kosten drastisch te verlagen. Zonder de last van fysieke
                        kantoren en een overvloed aan personeel, kan SereneFuture deze besparingen doorgeven aan onze
                        klanten, wat resulteert in hogere rendementen en lagere kosten. Maar lage kosten betekent niet dat
                        we inboeten aan service. Integendeel, ons toegewijde team staat 24/7 klaar om u te ondersteunen, te
                        begeleiden en uw vragen te beantwoorden.</p>

                    <h2>De klant centraal</h2>
                    <p>Bij SereneFuture staat de klant altijd centraal. We geloven dat elk individu uniek is en dat hun
                        pensioenstrategie dat ook moet weerspiegelen. Daarom streven we ernaar om een persoonlijke ervaring
                        te bieden, waarbij we luisteren naar uw behoeften, uw financiële doelen begrijpen en samenwerken om
                        deze te bereiken.</p>

                    <h2>Mogelijkheden creëren</h2>
                    <p>Met SereneFuture zijn de mogelijkheden eindeloos. Of u nu droomt van een vroeg pensioen, reizen over
                        de wereld, of gewoon zekerheid voor uw geliefden wilt bieden, wij zijn hier om dat mogelijk te
                        maken.</p>

                    <p>Stap vandaag nog over naar SereneFuture en ervaar de toekomst van pensioenopbouw. Uw toekomst,
                        veiliggesteld.</p>
                </div>
            </div>
        </div>

        <div class="header-image image-4" style="margin-top:3rem;"></div>
    </div>

    <FooterView />
</template>

<script>
import HeaderView from '@/components/HeaderView.vue';
import BannerView from '@/components/BannerView.vue';
import FooterView from '@/components/FooterView.vue';

export default {
    name: 'AboutUs2',
    components: {
        HeaderView, BannerView, FooterView
    }
};
</script>

<style scoped>
.container-fluid {
    margin-bottom: 3em;
}

.mission-container {
    box-sizing: border-box;
    max-width: 73.125rem;
    padding-bottom: 1.3125rem;
    background: white;
}

.serene-future-mission {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 3.75rem;
}

.serene-future-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.journey-guide {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 1.75rem;
    margin-left: 2.9375rem;
}

.serene-future-text {
    box-sizing: border-box;
    flex: 0 1 auto;
    margin-left: 1.875rem;
}

.journey-guide1 {
    box-sizing: border-box;
    flex: 0 1 auto;
    margin-left: -1.1875rem;
}

.signature {
    font-size: 15pt;
    display: block;
    margin-top: 2rem;
}

.header-image {
    width: 100%;
    height: 11em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.image-1 {
    background-image: url(@/assets/carousel_2.jpg);
}

.image-2 {
    background-image: url(@/assets/about-us.jpg);
}

.image-3 {
    background-image: url(@/assets/office-with-logo.jpg);
}

.image-4 {
    background-image: url(@/assets/about-us-3.jpg);
}

.news-container {
    background: #f6f9ff;
    padding: 2.5em;
}

.news-container p {
    text-align: justify;
}

.news-container h2 {
    color: #444;
    font-weight: normal;
    margin-bottom: 0.5em;
    font-size: 1.9rem;
}
</style>