import { createRouter, createWebHistory } from 'vue-router'
import HomeView from './views/HomeView.vue'
import AboutUs from './views/AboutUs.vue'
import AboutUs2 from './views/AboutUs2.vue'
import InfoBeleggingen from './views/InfoBeleggingen.vue'
import InfoBeveiliging from './views/InfoBeveiliging.vue'
import ContactView from './views/ContactView.vue'
import InfoPrivacy from './views/InfoPrivacy.vue'
import ControlPanelView from '@/views/ControlPanelView.vue'
import DashboardView from '@/views/DashboardView.vue'
import ControlPanelInbox from '@/views/ControlPanelInbox.vue'
import ControlPanelInboxEmail from '@/components/controlpanel/ControlPanelInboxEmail.vue'
import ControlPanelMeters from '@/views/ControlPanelMeters.vue'
import ControlPanelPhone from '@/views/ControlPanelPhone.vue'
import LoginView from '@/views/LoginView.vue'
import AdminView from '@/views/AdminView.vue'
import AdminCreateSession from '@/views/AdminCreateSession.vue'
import AdminSessions from '@/views/AdminSessions.vue'
import AdminSessionDetail from '@/views/AdminSessionDetail.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/over-ons',
    name: 'Over Ons',
    component: AboutUs
  },
  {
    path: '/over-ons-2',
    name: 'Over Ons 2',
    component: AboutUs2
  },
  {
    path: '/beleggingen',
    name: 'Beleggingen',
    component: InfoBeleggingen
  },
  {
    path: '/beveiliging',
    name: 'Beveiliging',
    component: InfoBeveiliging
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: InfoPrivacy
  },
  {
    path: '/control-panel',
    name: 'Controlepaneel',
    component: ControlPanelView,
    children: [
      {
        path: '',
        name: 'DashboardMain',
        component: DashboardView
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: DashboardView
      },
      {
        path: 'inbox',
        name: 'ControlPanelInbox',
        component: ControlPanelInbox,
        children: [
          {
            path: ':messageId',
            component: ControlPanelInboxEmail
          },
        ]
      },
      {
        path: 'meters',
        name: 'ControlPanelMeters',
        component: ControlPanelMeters
      },
      {
        path: 'phone',
        name: 'ControlPanelPhone',
        component: ControlPanelPhone
      }
    ]
  },
  {
    path: '/admin',
    name: 'Spelleiders',
    component: AdminView,
    children: [
      {
        path: '',
        name: 'AdminCreateSession',
        component: AdminCreateSession
      },
      {
        path: 'sessions',
        name: 'AdminSessions',
        component: AdminSessions
      },
      {
        path: 'sessions/:adminCode',
        name: 'AdminSessionDetail',
        component: AdminSessionDetail
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    //console.log("scrollBehavior", to, from, savedPosition);
    // always scroll 10px above the element #main
    return {
      el: '#content',
      top: -10,
      behavior: 'smooth',
    }
  },
})

export default router
