<template>
    <div v-if="isOpen" class="overlay">
        <div class="browser-window">
            <div class="app-header">
                <span class="app-title">Browser</span>
                <button @click="closeOverlay" class="close-button">sluiten</button>
            </div>
            <div class="tab-bar">
                <div class="tab">
                    <span>{{ pageTitle }}</span>
                </div>
            </div>
            <iframe :src="iframeURL" frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        iframeURL: {
            type: String,
            default: "",
        },
        pageTitle: {
            type: String,
            default: "Tab",
        }
    },
    methods: {
        closeOverlay() {
            this.$emit("close");
        },
    },
};
</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* pointer-events: all; */
}

.browser-window {
    width: 80%;
    height: 80%;
    background: white;
    position: relative;
    border-radius: 10px;
}

/* .browser-window,
.browser-window * {
    pointer-events: auto;
} */

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 7px;
    margin: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    /* Improving close button look */
    font-weight: bold;
    line-height: 1;
}

.close-button:hover {
    background-color: #c0392b;
}

.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #f5f5f5;
    /* border-bottom: 1px solid #ccc; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.app-title {
    font-size: 16px;
    color: #333;
    display: none;
}

iframe {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.tab-bar {
    background-color: #f5f5f5;
    padding: 0 10px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid #e0e0e0;
}

.tab {
    /* flex: 1; */
    min-width: 10em;
    background-color: #fff;
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    padding: 8px;
    text-align: center;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 0;
    font-size: 16px;
    color: #333;
    cursor: pointer;
}
</style>
