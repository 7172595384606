<template>
    <div style="line-height: 18pt; margin-bottom: 3em;">
        <span style="color: #d6d8db;">---</span><br />
        <span class="signature"><strong>Sjaak de Vries</strong><br /></span>
        <span class="signature">Accountmanager<br /></span>
        <img class="logo" src="@/assets/logo.png" width="50" />
        <span class="signature">
            <span class="icon-color"><font-awesome-icon icon="fa-outline fa-at" /></span> s.devries@puo.nl
            <br /></span>
        <span class="signature">
            <span class="icon-color"><font-awesome-icon icon="fa-outline fa-link" /></span> puo.nl
            <br /></span>
    </div>
</template>

<script>
export default {
    name: 'SjaakSignature',
};
</script>

<style scoped>
.signature {
    font-family: 'Roboto', sans-serif;
    font-size: 13pt;
    line-height: 10pt;
}

.signature .icon-color {
    color: var(--color-blue);
    margin-right: 10px;
    width: 1em;
    display: inline-block;
    text-align: center;
}

.logo {
    float: left;
    margin-right: 10px;
}
</style>