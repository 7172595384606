<template>
    <div class="container mt-5">
        <div class="row">
            <h1 class="caption">Nieuws</h1>
            <div class="news-container">
                <h2>{{ news.title }}</h2>
                <p v-html="news.body">

                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewsView',
    props: {
        news: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
.news-container {
    background: var(--color-blue-light);
    padding: 2.5em;
}

.news-container p {
    text-align: justify;
}

.news-container h2 {
    color: #444;
    font-weight: normal;
    margin-bottom: 0.5em;
}
</style>
