<template>
    <div class="adjust-meters-form control-panel-content">
        <form @submit.prevent="submitForm">
            <div class="form-group-wrapper">
                <div class="form-group form-group-horizontal" v-for="i in 5" :key="i">
                    <label :for="'meter' + i">{{ meterName[i - 1] }}:</label>
                    <input type="number" :id="'meter' + i" v-model="formData['meter' + i]" />
                </div>
            </div>
            <div class="form-group">
                <label for="answer">Bericht:</label>
                <input type="text" id="answer" v-model="formData.answer" placeholder="Meters aangepast omdat..." />
            </div>
            <ButtonView class="btn-save" text="Opslaan" type="submit" />
            <span class="is-saved" v-if="isSaved">Aanpassing opgeslagen</span>
        </form>
    </div>
</template>

<script>
import api from '@/repository/api';
import gametree from '@/repository/gametree';
import ButtonView from '../controlpanel/ButtonView.vue';

export default {
    props: {
        session: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            meterName: [
                "T", "D", "RM", "W", "KD"
            ],
            formData: {
                answer: '',
                meter1: 0,
                meter2: 0,
                meter3: 0,
                meter4: 0,
                meter5: 0
            },
            isSaved: false
        };
    },
    methods: {
        async submitForm() {
            const adminCode = this.$route.params.adminCode;
            const token = this.$store.state.sessionToken;
            const { answer, meter1, meter2, meter3, meter4, meter5 } = this.formData;
            const dilemma = this.session.progress;
            const variant = this.session.variant;
            const message = `${dilemma}: ${gametree.progressStates(variant)[dilemma].title}`;
            try {
                await api.adjustMeters(token, adminCode, message, answer, meter1, meter2, meter3, meter4, meter5);
                // Handle successful submission (like clearing the form or showing a message)
                this.formData.answer = '';
                this.formData.meter1 = 0;
                this.formData.meter2 = 0;
                this.formData.meter3 = 0;
                this.formData.meter4 = 0;
                this.formData.meter5 = 0;
                this.isSaved = true;
                setTimeout(() => {
                    this.isSaved = false;
                }, 5000);
            }
            catch (error) {
                // Handle error (like showing an error message to the user)
                console.error('Error adjusting meters:', error);
            }
        }
    },
    components: {
        ButtonView
    }
}
</script>

<style scoped>
#answer {
    width: calc(96% - 5rem);
}

/* You can style your form here. For simplicity, I've added basic styles. */
.form-group {
    margin-bottom: 20px;
}

label {
    display: inline-block;
    width: 5rem;
    margin-right: 1em;
}

input {
    padding: 0.25em 0.5em;
    border-radius: 11px;
    border: 1px solid grey;
}

.form-group-horizontal label {
    width: unset;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.form-group-wrapper {
    display: flex;
}

.form-group-horizontal input {
    width: 5.5rem;
}

.btn-save {
    height: 2em;
    width: auto;
    padding-left: 1em;
    padding-right: 1em;
}

.is-saved {
    margin: 2rem;
    font-weight: bold;
}

.btn-save {
    margin-left: auto;
}
</style>
