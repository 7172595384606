<template>
    <div class="control-panel-content">
        <h3 v-if="session.progress < 1">Spelers zijn nog niet ingelogd.</h3>
        <h3 v-if="session.progress >= 1 && !gamePaused">Speeltijd: {{ showDuration(elapsedTime / 1000) }}
            [&nbsp;<a class="btn-pause" @click.prevent="pauseGame">Pauzeren</a>&nbsp;]</h3>
        <h3 v-if="session.progress >= 1 && session.progress < 24 && gamePaused">Speeltijd: {{ showDuration(elapsedTime /
            1000) }}
            [&nbsp;<a class="btn-pause" @click.prevent="resumeGame">Hervatten</a>&nbsp;]</h3>
        <h3 v-if="session.progress == 24">Spel afgelopen.</h3>

        <div class="dilemmas-list">
            <ul>
                <li v-for="(state, index) in progressStates" :key="index" :class="getItemClass(index)"
                    @click="advanceToDilemma(index, false)">
                    <span class="time" :title="showStartAt(state, false)">{{ showStartAt(state, true) }}</span>
                    <span class="number">{{
                        state.dilemmaId
                    }}</span>
                    <span class="icons">
                        <font-awesome-icon icon="fa-regular fa-clock"
                            v-if="state.answers.length > 0 && state.duration > 0 && !state.isDone && !state.isTooLate" />
                        <img src="@/assets/ic_done.png" width="32" v-if="state.isDone && !state.isTooLate" />
                        <img src="@/assets/ic_too_late.png" width="32" v-if="state.isTooLate" />
                        <img src="@/assets/ic_email_important.png" width="32"
                            v-if="!state.isDone && !state.isTooLate && state.answers.length > 0" />
                    </span>
                    {{ state.title }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import api from '@/repository/api';
import { mapState } from 'vuex';

export default {
    props: {
        session: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            progressStates: this.session.steps,
            currentStep: this.getUpdatedProgress(),
            gamePaused: this.session.isPaused,
            gameEnded: this.session.isEnd,
            totalPauseDuration: 0,
            gameInterval: null,
            elapsedTime: 0,
            timeToNextStep: 0,
            lastStepTime: 0,
            isRound2: false,
        }
    },
    watch: {
        session() {
            this.gamePaused = this.session.isPaused;
            this.progressStates = this.session.steps;
        },
    },
    mounted() {
        // Start the automatic advancement of the game
        this.startClock();
    },
    computed: {
        getItemClass() {
            return (index) => {
                if (index < this.currentStep) return 'past-dilemma';
                if (index === this.currentStep) return 'current-dilemma';
                if (index > this.currentStep) return 'future-dilemma';
            };
        }
    },
    methods: {
        ...mapState(['session']),
        getUpdatedProgress() {
            if (this.session) {
                const now = Date.now();
                return this.session.steps.findLastIndex(step => step.startTime != null && now > new Date(step.startTime).getTime());
            } else {
                return 0;
            }
        },
        startClock() {
            this.stopClock
            this.gameInterval = setInterval(this.tick, 1000);
        },
        stopClock() {
            if (this.gameInterval) {
                clearInterval(this.gameInterval);
            }
        },
        tick() {
            const now = new Date().getTime();
            if (this.session.startTimeRound2) {
                this.isRound2 = true;
            }
            const previousStep = this.currentStep;
            this.currentStep = this.getUpdatedProgress();
            const hasEnded = this.currentStep >= this.session.steps.length;

            if (!hasEnded && !this.gamePaused) {
                let startTime = Date.parse(this.session.startTime);
                this.elapsedTime = now - startTime - this.totalPauseDuration;
            }
            if (this.currentStep != previousStep && this.progressStates[this.currentStep].isPause) {
                this.pauseGame();
            }
            if (this.currentStep == this.progressStates.length - 1) {
                this.gamePaused = true;
            }
        },
        advanceToDilemma(index, force) {
            if (this.gamePaused) {
                window.alert("Kan niet doorgaan naar een andere stap terwijl het spel gepauzeerd is.");
                return;
            }

            const dilemmaId = this.progressStates[index].dilemmaId;
            if (force || window.confirm(`Weet je zeker dat je door wilt gaan naar stap ${dilemmaId}?`)) {
                // Advance the game's progress.
                const previousStep = this.currentStep;
                this.currentStep = index;
                const adminCode = this.$route.params.adminCode;
                const token = this.$store.state.sessionToken;
                try {
                    api.continueToProgressState(token, adminCode, previousStep, index);
                } catch (error) {
                    console.error("Error updating game progress: ", error);
                }
            }

            // If this step is the pause, automatically pause here.
            if (this.progressStates[index].isPause) {
                this.pauseGame();
            }
        },
        async pauseGame() {
            // Pause game
            this.gamePaused = true;
            // Notify backend
            const adminCode = this.$route.params.adminCode;
            const token = this.$store.state.sessionToken;
            try {
                await api.pauseGame(token, adminCode);
            } catch (error) {
                console.error("Error pausing game: ", error);
            }
        },
        async resumeGame() {
            // Update timings if we are resuming from the big break
            var startRound2 = false;
            if (this.progressStates[this.currentStep].isPause) {
                this.isRound2 = true;
                startRound2 = true;
            }

            // Resume game
            this.gamePaused = false;

            // Notify backend
            const adminCode = this.$route.params.adminCode;
            const token = this.$store.state.sessionToken;
            try {
                const response = await api.resumeGame(token, adminCode, this.currentStep, startRound2);
                this.totalPauseDuration = response.data.data;
                console.log(`Game resumed, total pause duration: ${this.totalPauseDuration} millis`);
            } catch (error) {
                console.error("Error pausing game: ", error);
            }
        },
        beforeDestroy() {
            clearInterval(this.gameInterval);
        },
        showDuration(duration) {
            const seconds = Math.floor(duration);
            if (seconds < 60) {
                return `${seconds} seconden`;
            } else {
                const minutes = Math.floor(seconds / 60);
                const remainingSeconds = seconds % 60;
                return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}m`;
            }
        },
        showStartAt(state, shortHand) {
            // Not all steps have an automatic start time
            // if (state.startAt <= 0 || this.session.progress < 1 || !state.isStartTimeCalculated) {
            //     return "--:--";
            // }
            // Hide start times for round 2 if we don't know what time round 2 starts yet
            if (state.startTime == null) {
                return "--:--";
            }
            if (shortHand) {
                const date = new Date(state.startTime);
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                return `${hours}:${minutes}`;
            } else {
                try {
                    const date = new Date(state.startTime);
                    return date.toISOString();
                } catch (error) {
                    return state.startTime;
                }
            }
        }
    }
}
</script>

<style scoped>
.dilemmas-list {
    height: 14rem;
    overflow-y: auto;
    border: 2px solid var(--color-blue);
    border-radius: 10px;
    box-shadow: 0rem 0.25rem 0.25rem inset rgba(0, 0, 0, 0.25);
    background: white;
    margin-top: 0.8em;
}

.past-dilemma {
    color: grey;
}

.current-dilemma {
    font-weight: bold;
}

.future-dilemma {
    color: var(--color-blue);
    cursor: pointer;
}

.future-dilemma:hover {
    text-decoration: underline;
}

.btn-pause {
    color: var(--color-blue);
    text-decoration: underline !important;
    cursor: pointer;
}

.number {
    display: inline-block;
    width: 4em;
}

.time {
    color: #000;
    display: inline-block;
    width: 3em;
}
</style>
