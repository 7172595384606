
const game = {
    kccEnabled: true,
    portalEnabled: true,
    // 0 = the start, around 23b is completed, game finished.
    progress: 0,
    inbox: [
        // NOTES
        // - id should be unique
        // - dilemmaId is the id in the gametree, that is associated with the email. Used to lookup delays and durations.

        // Ronde 2
        { id: 'stap-23b', dilemmaId: 'stap-23', subject: 'Beveiligingsprobleem binnen uw organisatie', from: 'datalockgrp@protonmail.me', sender: 'DataLockGroup', isRead: false, visible: false, 'type': 'email', important: true, avatar: 'ic_avatar_hacker.jpg' },
        //{ id: 'stap-23', dilemmaId: null, subject: "VerlinkedIn update in your network", from: 'noreply@verlinkedin.com', sender: 'VerlinkedIn', receiver: 'Bestuur SereneFuture', isRead: false, visible: false, 'type': 'linkedin', important: false, avatar: 'ic_avatar_linkedin.png' },
        { id: 'stap-22', dilemmaId: "stap-22", subject: "Forensisch rapport", from: 'f.boulanger@serenefuture.nl', sender: 'Ferdy', receiver: 'SereneFuture', isRead: false, visible: false, 'type': 'news', important: false, avatar: 'ic_avatar_ferdy.jpg' },
        { id: 'stap-21b', dilemmaId: "stap-21b", subject: "PERSBERICHT", from: 'ANP', sender: 'ANP', receiver: 'SereneFuture', isRead: false, visible: false, 'type': 'news', important: false, avatar: 'ic_avatar_anp.png' },
        { id: 'stap-20', dilemmaId: "stap-20", subject: "Bericht op Weinigstijl", from: 'f.boulanger@serenefuture.nl', sender: 'Ferdy', receiver: 'Bestuur', isRead: false, visible: false, 'type': 'news', important: false, avatar: 'ic_avatar_ferdy.jpg' },
        { id: 'stap-21', dilemmaId: "stap-21", subject: "Update over proactieve acties n.a.v. phishingberichten en gegevensmutaties", sender: 'S. De Vries', from: 's.devries@puo.nl', receiver: 'Bestuur', isRead: false, visible: false, 'type': 'email', important: false, avatar: 'ic_avatar_sjaak.jpg' },
        { id: 'stap-19', dilemmaId: "stap-19", subject: "URGENT: Mogelijk datalek bij uw pensioenfonds", from: 'f.boulanger@serenefuture.nl', sender: 'Ferdy', receiver: 'Bestuur', isRead: false, visible: false, 'type': 'post', important: true, avatar: 'ic_avatar_ferdy.jpg' },
        { id: 'stap-18', dilemmaId: "stap-18", subject: "Correctie update betreffende het eerder genoemde geïsoleerde systeem", from: 's.devries@puo.nl', sender: 'S. De Vries', receiver: 'Bestuur', isRead: false, visible: false, type: 'email', important: false, avatar: 'ic_avatar_sjaak.jpg' },
        { id: 'stap-17', dilemmaId: 'stap-17', subject: "PERSBERICHT", from: 'ANP', sender: 'ANP', receiver: 'SereneFuture', isRead: false, visible: false, type: 'news', important: true, avatar: 'ic_avatar_anp.png' },
        // { id: 'stap-16b', dilemmaId: null, subject: "Bericht op Y", from: 'SecurityDaily', sender: '@security_daily', receiver: '', isRead: false, visible: false, type: 'y', important: false, avatar: null },
        { id: 'stap-16', dilemmaId: "stap-16", subject: "Fwd: Gegevens op Darkweb", from: 's.devries@puo.nl', sender: 'S. De Vries', receiver: 'Bestuur', isRead: false, visible: false, type: 'email', important: false, avatar: 'ic_avatar_sjaak.jpg' },
        { id: 'stap-15', dilemmaId: 'stap-15', subject: "Uw verzoek om aanvullende informatie", from: 's.devries@puo.nl', sender: 'S. De Vries', receiver: 'Bestuur', isRead: false, visible: false, type: 'email', important: true, avatar: 'ic_avatar_sjaak.jpg' },
        { id: 'stap-14', dilemmaId: "stap-14", subject: "VerlinkedIn update in your network", from: 'noreply@verlinkedin.com', sender: 'VerlinkedIn', receiver: 'Bestuur SereneFuture', isRead: false, visible: false, type: 'linkedin', important: false, avatar: 'ic_avatar_linkedin.png' },
        { id: 'stap-13', dilemmaId: 'stap-13', subject: "Vervolgstappen?", from: 'f.boulanger@serenefuture.nl', sender: 'Ferdy', receiver: 'Bestuur', isRead: false, visible: false, type: 'email', important: true, avatar: 'ic_avatar_ferdy.jpg' },
        { id: 'stap-12', dilemmaId: 'stap-12', subject: "Verzoek tot Samenwerking voor Artikel betreft Datalek", from: 'c.vdbeek@pensioenpro.nl', sender: 'Camilla van den Beek', receiver: 'SereneFuture', isRead: false, visible: false, type: 'email', important: true, avatar: 'ic_avatar_pensioenpro.png' },
        { id: 'stap-10', dilemmaId: "stap-10", subject: "Update betreffende het eerder genoemde geïsoleerde systeem", from: 's.devries@puo.nl', sender: 'S. De Vries', receiver: 'Bestuur', isRead: false, visible: false, type: 'email', important: false, avatar: 'ic_avatar_sjaak.jpg' },
        // Ronde 1
        { id: 'stap-9', dilemmaId: "stap-9", subject: "Update betreffende mediaberichten over gewijzigde deelnemersgegevens", from: 's.devries@puo.nl', sender: 'S. De Vries', receiver: 'Bestuur', isRead: false, visible: false, type: 'email', important: true, avatar: 'ic_avatar_sjaak.jpg' },
        { id: 'stap-8b', dilemmaId: "stap-8", subject: "Fragment radio", sender: 'Business Radio RS1 FM', from: 'Business Radio RS1 FM', receiver: 'RS1 FM', isRead: false, visible: false, 'type': 'radio', important: true, avatar: 'ic_avatar_radio.png', videoFile: '/attachments/radiouitzending.mp4' },
        // { id: 'stap-8c', dilemmaId: null, subject: "Tussenstand bekend", from: 'f.boulanger@serenefuture.nl', sender: 'Ferdy', receiver: 'Bestuur', isRead: false, visible: false, type: 'email', important: false, avatar: 'ic_avatar_ferdy.jpg' },
        { id: 'stap-7', dilemmaId: "stap-7c", subject: "Update betreffende het onderzoek naar de recente anomalie en mediaberichtgeving", sender: 'S. De Vries', from: 's.devries@puo.nl', receiver: 'Bestuur', isRead: false, visible: false, 'type': 'email', important: false, avatar: 'ic_avatar_sjaak.jpg' },
        {
            dilemmaId: "stap-7b",
            from: "Fred de Haan",
            audioFile: '/attachments/voicemail-stap-7.mp3',
        },
        { id: 'stap-8', dilemmaId: "stap-7a", subject: "Voicemail doorgestuurd", sender: 'Tessa Willemsen', from: 't.willemsen@serenefuture.nl', receiver: 'Bestuur', isRead: false, visible: false, 'type': 'email', important: false, avatar: 'ic_avatar_tessa.jpg' },
        { id: 'stap-6', dilemmaId: "stap-6", subject: "Bericht op Y", from: 'MASS Mailing', sender: '@MassMailing', receiver: '', isRead: false, visible: false, 'type': 'y', important: false, avatar: null },
        { id: 'stap-5', dilemmaId: "stap-5", subject: "Een ontvangen melding aangaande phishing / spam", from: 'f.boulanger@serenefuture.nl', sender: 'Ferdy', receiver: 'Bestuur', isRead: false, visible: false, 'type': 'post', important: true, avatar: 'ic_avatar_ferdy.jpg' },
        { id: 'stap-4', dilemmaId: "stap-4", subject: 'Tijdelijke onbeschikbaarheid van systemen', from: 's.devries@puo.nl', receiver: 'iedereen', sender: 'S. De Vries', isRead: false, visible: false, type: 'email', important: true, avatar: 'ic_avatar_sjaak.jpg' },
        { id: 'stap-3', dilemmaId: "stap-3", subject: 'Informeren over deelnemergegevens online', from: 's.devries@puo.nl', receiver: 'iedereen', sender: 'S. De Vries', isRead: false, visible: false, type: 'email', important: true, avatar: 'ic_avatar_sjaak.jpg' },
        { id: 'stap-2', dilemmaId: "stap-2", subject: 'Informeren over recente externe ontwikkelingen en behoefte aan uniforme communicatierichtlijnen', from: 's.devries@puo.nl', receiver: 'iedereen', sender: 'S. De Vries', isRead: false, visible: false, type: 'email', important: true, avatar: 'ic_avatar_sjaak.jpg' },
        { id: 'email3', dilemmaId: "stap-1c", subject: 'Graag netjes opruimen', from: 'hans@serenefuture.nl', receiver: 'iedereen', sender: 'Hans', isRead: true, visible: true, date: '7:50', type: 'email', avatar: 'ic_avatar_hans.jpg' },
        { id: 'email1', dilemmaId: "stap-1b", subject: 'Taart in de keuken!', from: 'jacqueline@serenefuture.nl', receiver: 'iedereen', sender: 'Jacqueline', isRead: true, visible: true, date: 'Gisteren, 8:58', type: 'email', avatar: 'ic_avatar_jacq.jpg' },
    ],
    websiteMessages: [
        { id: 'stap-2', title: 'SereneFuture waarschuwt voor phishing!', body: 'Het is ons ter ore gekomen dat malafide online handelaren gegevens proberen te achterhalen door het sturen van zogenoemde phishing mails. Ook deelnemers van SereneFuture hebben dergelijke emails ontvangen. Wees daarom waakzaam: deze emails lijken van SereneFuture te komen, maar zijn nep! Herkent u phishing? Mails van ons komen altijd van serenefuture.nl. Herkent u de afzender niet? Reageer dan niet op de mail! SereneFuture zal u nooit vragen om uw wachtwoord.<br /><br />Wij wensen u een veilige en serene dag.', visible: false }
    ],
    phonebook: [
        { name: 'Autoriteit persoonsgegevens', number: '06-PLACE-HOLDER', visible: true, isDynamic: true },
        // { name: 'Klantcontactcentrum PUO', number: '123', visible: true },
        { name: 'Sytse Vliegen', number: 'Nummer onbekend', visible: true },
        //{ name: 'MailMonkey support team', number: '06-1234-5678', visible: true },
        { name: 'Jacqueline (van HR)', number: 'Nummer onbekend', visible: true },
    ]
};

module.exports = {
    game
};
