<template>
    <h1 class="control-panel-title">Nieuwe sessie</h1>
    <div class="control-panel-content">
        <p>Hier kunt u een nieuwe spelsessie aanmaken.</p>
        <div style="display: none;">
            <p class="labelPhone">
                Telefoonnummer spelleider:
            </p>
            <InputView v-model="phoneNumber" hint="06-..." class="inputPhone" />
            <p class="notePhone">
                (Dit nummer wordt getoond aan de spelers als de 'Autoriteit Persoonsgegevens')
            </p>
        </div>
        <div class="start-buttons">
            <ButtonView text="Basis" @click="create(0)" />
            <ButtonView text="Gevorderd" @click="create(1)" />
            <ButtonView text="Expert" @click="create(2)" />
        </div>
        <p class="new-admin-code" v-if="newAdminCode">
            Nieuwe spelcode:
            <router-link :to="'/admin/sessions/' + newAdminCode"><span class="large">{{ newAdminCode }}</span></router-link>
            <span class="small">Geef deze spelcode aan de spelers.</span>
        </p>
    </div>
</template>

<script>
import api from '@/repository/api';
import InputView from '@/components/controlpanel/InputView.vue'
import ButtonView from '@/components/controlpanel/ButtonView.vue'

export default {
    data() {
        return {
            newAdminCode: '',
            phoneNumber: ''
        }
    },
    methods: {
        async create(variant) {
            const token = this.$store.state.sessionToken;
            const response = await api.createSession(token, variant, this.phoneNumber);
            const data = response.data;
            console.log(data);
            // show the admin code in the box
            this.newAdminCode = data.data.adminCode;
        }
    },
    components: { ButtonView, InputView }
}
</script>

<style scoped>
.new-admin-code {
    margin-top: 1rem;
}

.start-buttons {
    display: flex;
}

.start-buttons .admin-button {
    margin: 0.5em;
}

.inputPhone {
    margin: 0.5em;
    width: 20em;
}

.labelPhone {
    font-weight: bold;
    ;
}

.notePhone {
    font-size: 0.75em;
}
</style>
