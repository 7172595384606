<template>
    <input :type="type" pattern="[A-Za-z0-9]*" :placeholder="hint" :value="value" class="form-control input-lg"
        autocomplete="off" data-lpignore="true" @input="updateValue($event.target.value)" />
</template>

<script>
export default {
    props: {
        hint: {
            type: String,
            required: true,
        },
        value: String,
        type: {
            type: String,
            required: false,
            default: "text"
        }
    },
    methods: {
        updateValue(newValue) {
            this.$emit('input', newValue.toUpperCase());
        }
    }
}
</script>

<style scoped>
input {
    width: 21.5rem;
    height: 5.8125rem;
    color: var(--color-blue);
    font-size: 2rem;
    text-align: center;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    background: white;
    border: 0.1875rem solid var(--color-blue);
    border-radius: 1.125rem;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
}
</style>