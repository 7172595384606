<template>
    <h1 class="control-panel-title" id="inbox-top">Inbox</h1>
    <div class="control-panel-content container-fluid inbox">
        <table class="table">
            <tbody>
                <tr v-for="email in emails" :key="email.id" :class="[email.isRead ? 'email-read' : 'email-unread']">
                    <td class="td-icon">
                        <router-link :to="email.route">
                            <img v-if="email.isRead && email.type == 'email'" class="email-icon"
                                src="@/assets/ic_email_read.svg" />
                            <img v-if="!email.isRead && email.type == 'email'" class="email-icon"
                                src="@/assets/ic_email_unread.svg" />
                            <img v-if="email.type == 'skype'" class="email-icon" src="@/assets/ic_skype.svg" />
                            <img v-if="email.type == 'news'" class="email-icon" src="@/assets/ic_news.svg" />
                            <img v-if="email.type == 'post'" class="email-icon" src="@/assets/ic_post.png" />
                            <img v-if="email.type == 'y'" class="email-icon" src="@/assets/ic_twotter.png" />
                            <img v-if="email.type == 'linkedin'" class="email-icon" src="@/assets/ic_linkedin.png" />
                            <img v-if="email.type == 'radio'" class="email-icon" src="@/assets/ic_radio.png" />
                        </router-link>
                    </td>
                    <td class="td-sender">
                        <router-link :to="{ path: email.route, hash: '#content' }">
                            {{ email.sender }}
                        </router-link>
                    </td>
                    <td class="td-subject">
                        <router-link :to="{ path: email.route, hash: '#content' }">
                            <span class="alert-icon bounce-small" v-if="email.showBounce">
                                <img class="bouncy-icon" />
                            </span>
                            {{ email.subject }}
                        </router-link>
                    </td>
                    <td class="td-date">
                        <router-link :to="{ path: email.route, hash: '#content' }">
                            {{ email.date }}
                        </router-link>
                    </td>
                    <td class="td-important">
                        <span class="span-important"
                            v-if="email.important && !email.isDone && !email.isTooLate">ACTIE&nbsp;VEREIST</span>
                        <span class="span-important"
                            v-if="email.important && email.isTooLate && !email.isDone">TE&nbsp;LAAT</span>
                        <span class="span-done" v-if="email.important && email.isDone">AFGEHANDELD</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="control-panel-content email-content container-fluid" style="margin-top: 1rem;"
        v-if="$route.params.messageId" id="content">
        <router-view></router-view>
    </div>
</template>

<script>
import { game } from '@/repository/gamestate';
import { mapState } from 'vuex';

export default {
    name: "ControlPanelInbox",
    data() {
        return {
            emails: [],
        }
    },
    mounted() {
        this.emails = this.filterEmails();
    },
    computed: mapState(['session']),
    watch: {
        session() {
            this.emails = this.filterEmails();
        },
    },
    methods: {
        prettyTime(timeStr) {
            const time = new Date(timeStr);
            const hours = String(time.getHours()).padStart(2, '0');
            const minutes = String(time.getMinutes()).padStart(2, '0');
            return `${hours}:${minutes}`;
        },
        filterEmails() {
            let emails = [];
            const steps = this.$store.state.session.steps;
            const now = Date.now();
            if (steps) {
                // For each of the known items in the inbox, check if they have a matching
                // startTime in the session and show it if so.
                game.inbox.forEach(step => {
                    const dilemma = steps.find(s => s.dilemmaId == step.dilemmaId);
                    if (dilemma
                        && dilemma.startTime
                        && now > new Date(dilemma.startTime).getTime()
                        && !dilemma.isVoicemail) {
                        step.route = '/control-panel/inbox/' + step.id;
                        step.isRead = dilemma.isRead;
                        step.isDone = dilemma.isDone;
                        step.isTooLate = dilemma.isTooLate;
                        step.showBounce = dilemma.duration > 0 && step.important && !step.isDone && !step.isTooLate;
                        if (!step.date) {
                            step.date = this.prettyTime(dilemma.startTime);
                        }
                        emails.push({
                            ...step
                        });
                    }
                });
            }
            return emails;
        }
    }
}
</script>

<style scoped>
.inbox {
    max-height: 20em;
    overflow-y: auto;
    overflow-x: hidden;
}

.email-icon {
    width: 2rem;
    height: 2rem;
}

.email-content {
    background: white;
}

tr.email-read {
    border-radius: 1rem;
}

td a {
    color: black;
}

.email-read td,
.email-unread td {
    font-size: 1.6rem;
}

.email-read {
    background-color: transparent;
}

.email-unread {
    font-weight: 700;
}

.router-link-exact-active {
    /* color: var(--color-blue); */
    text-decoration: underline;
}

.email-unread td {
    background-color: #DCE6FF;
}

.email-unread td:first-child {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}

td:first-child {
    width: 4rem;
}

td:first-child {
    padding-left: 1.5rem;
    padding-right: 0;
}

.email-unread td:last-child {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

table {
    border-collapse: unset;
    border-spacing: 0px;
}

.table th {
    border-top: 0;
    border-bottom: 0;
}

tr,
td {
    border: 0;
    border-top: 0;
    border-bottom: 1px solid lightgray;
}

td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 10em;
}

.td-sender {
    max-width: 10em;
}

.td-subject {
    max-width: 10em;
}

.td-date {
    max-width: 6em;
}

.span-done {
    display: block;
    padding-left: 32px;
    background-image: url("@/assets/ic_done.png");
    background-repeat: no-repeat;
    background-position: left center;
}

.alert-icon {
    display: inline-block;
}

.bouncy-icon {
    content: url("data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%27314.8335%20510.7501%2038.3331%2034.4996%27%20x%3D%270%27%20y%3D%270%27%20fill%3D%27none%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20id%3D%27Icon_6%27%20d%3D%27M334%20529.725V521.128M334%20536.097V536.173M344.867%20543.333H323.133C320.164%20543.333%20317.658%20541.371%20316.87%20538.687C316.534%20537.541%20316.947%20536.347%20317.579%20535.331L328.447%20515.735C330.993%20511.644%20337.007%20511.644%20339.553%20515.735L350.421%20535.331C351.053%20536.347%20351.466%20537.541%20351.13%20538.687C350.342%20541.371%20347.836%20543.333%20344.867%20543.333Z%27%20stroke%3D%27white%27%20stroke-width%3D%273.83333%27%20stroke-linecap%3D%27round%27%20stroke-linejoin%3D%27round%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%2F%3E%3C%2Fsvg%3E");
    /* Make the icon black */
    filter: invert();
    width: 1.2rem;
    height: 1.2rem;
}
</style>
