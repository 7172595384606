<template>
    <div class="blue-container">
        <div class="profile-card">
            <img class="img-content-33bd89f4" src="@/assets/logo.png" width="140" />
        </div>
        <div class="info-container">
            <p class="information-title">Informatie</p>
            <div class="section-type-container">
                <p class="section-type-class section-type"><router-link to="/">Start</router-link></p>
                <p class="section-type-class section-type"><router-link to="/over-ons">Over Ons</router-link></p>
                <p class="section-type-class section-type"><router-link to="/beleggingen">Beleggingen</router-link></p>
                <p class="section-type-class section-type"><router-link to="/beveiliging">Beveiligingsbeleid</router-link>
                </p>
                <!-- <p class="section-type-class section-type"><router-link to="/contact">Contact</router-link></p> -->
                <p class="section-type-class section-type"><router-link to="/privacy">Privacy</router-link></p>
            </div>
        </div>
        <div class="social-media-container">
            <div class="social-media-container1">
                <p class="social-media-heading">Sociale Media</p>
                <div class="social-media-icons-container">
                    <div class="svg-container">
                        <svg viewBox="0 0 20 20" x="0" y="0" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <clipPath id="clip1_158_82" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="10" height="19" fill="white"
                                        transform="translate(5.355800000000045, 0.5050000000001091)" />
                                </clipPath>
                            </defs>
                            <g id="facebook" clip-path="url(#clip1_158_82)" xmlns="http://www.w3.org/2000/svg">
                                <path id="Shape" fill-rule="evenodd" clip-rule="evenodd"
                                    d="M11.4928,19.495h-4.091v-9.49h-2.045v-3.27h2.045v-1.97c0,-2.67 1.155,-4.26 4.433,-4.26h2.73v3.28h-1.705c-1.278,0 -1.362,0.45 -1.362,1.31l-0.005,1.64h3.092l-0.362,3.27h-2.73z"
                                    fill="currentColor" />
                            </g>
                        </svg>
                    </div>
                    <div class="svg-container1">
                        <svg viewBox="0 0 24 24" x="0" y="0" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <clipPath id="clip2_158_82" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="22" height="17" fill="white"
                                        transform="translate(0.9054000000000997, 4.13169999999991)" />
                                </clipPath>
                            </defs>
                            <g id="twitter" clip-path="url(#clip2_158_82)" xmlns="http://www.w3.org/2000/svg">
                                <path id="Shape_2" fill-rule="evenodd" clip-rule="evenodd"
                                    d="M21.9134,6.0317c-0.773,0.32 -1.604,0.53 -2.475,0.63c0.89,-0.5 1.573,-1.29 1.895,-2.23c-0.834,0.46 -1.756,0.79 -2.738,0.98c-0.785,-0.79 -1.906,-1.28 -3.145,-1.28c-2.38,0 -4.311,1.81 -4.311,4.04c0,0.32 0.038,0.63 0.112,0.92c-3.582,-0.17 -6.758,-1.78 -8.884,-4.22c-0.371,0.6 -0.583,1.29 -0.583,2.03c0,1.4 0.761,2.64 1.917,3.36c-0.706,-0.02 -1.37,-0.2 -1.952,-0.5c0,0.02 0,0.03 0,0.05c0,1.96 1.485,3.59 3.457,3.96c-0.361,0.09 -0.743,0.14 -1.135,0.14c-0.278,0 -0.548,-0.02 -0.811,-0.07c0.548,1.6 2.14,2.77 4.026,2.8c-1.475,1.09 -3.333,1.73 -5.353,1.73c-0.348,0 -0.691,-0.02 -1.028,-0.05c1.907,1.14 4.173,1.81 6.607,1.81c7.928,0 12.263,-6.15 12.263,-11.49c0,-0.18 -0.005,-0.35 -0.013,-0.52c0.842,-0.57 1.573,-1.29 2.151,-2.09z"
                                    fill="currentColor" />
                            </g>
                        </svg>
                    </div>
                    <div class="svg-container">
                        <svg viewBox="0 0 20.977 19.02" x="0" y="0" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <clipPath id="clip3_158_82" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="20" height="20" fill="white"
                                        transform="translate(0.48900000000003274, -0.4899999999997817)" />
                                </clipPath>
                            </defs>
                            <g id="Linked In" clip-path="url(#clip3_158_82)" xmlns="http://www.w3.org/2000/svg">
                                <path id="Shape_3" fill-rule="evenodd" clip-rule="evenodd"
                                    d="M19.001,18.52h-17.024c-0.816,0 -1.477,-0.58 -1.477,-1.29v-15.44c0,-0.71 0.661,-1.29 1.477,-1.29h17.024c0.815,0 1.476,0.58 1.476,1.29v15.44c0,0.71 -0.661,1.29 -1.476,1.29zM3.962,15.55h2.942v-8.13h-2.942zM5.453,3.5c-1.007,0 -1.664,0.6 -1.664,1.4c0,0.78 0.638,1.41 1.625,1.41h0.019c1.026,0 1.664,-0.63 1.664,-1.41c-0.018,-0.8 -0.638,-1.4 -1.644,-1.4zM17.512,10.89c0,-2.5 -1.452,-3.66 -3.387,-3.66c-1.562,0 -2.26,0.79 -2.651,1.34v-1.15h-2.942c0.039,0.76 0,8.13 0,8.13h2.942v-4.54c0,-0.24 0.019,-0.49 0.097,-0.66c0.212,-0.48 0.697,-0.99 1.509,-0.99c1.065,0 1.49,0.75 1.49,1.84v4.35h2.942zM11.454,8.6c0.006,-0.01 0.014,-0.02 0.02,-0.03v0.03z"
                                    fill="currentColor" />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterView',
}
</script>

<style scoped>
.blue-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    min-width: 90rem;
    padding: 2.9375rem 3rem 3.1875rem 3.9375rem;
    background: #5f8bff;
}

.profile-card {
    width: 201px;
}

.img-content-33bd89f4 {
    content: url("@/assets/logo.png");
    width: 140px;
    height: auto;
}

.info-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 20.24%;
    padding: 0.9375rem 0.9375rem 5.375rem;
}

.information-title {
    box-sizing: border-box;
    flex: 0 0 auto;
    font: 700 1rem Roboto;
    color: white;
}

.section-type-container {
    box-sizing: border-box;
    flex: 0 0 auto;
    margin-top: 2rem;
}

.section-type-class {
    box-sizing: border-box;
    font: 500 0.875rem Roboto;
    color: white;
    letter-spacing: 0.03em;
}

.section-type {
    margin-top: 1.648125rem;
}

.section-type-class:first-child {
    margin-top: 0rem;
}

.social-media-container {
    box-sizing: border-box;
    /* display: flex; */
    flex: 0 0 auto;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    width: 51.09%;
    padding: 0.75rem 0.9375rem 15.125rem;
}

.social-media-container1 {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
}

.social-media-heading {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
    font: 700 1rem Roboto;
    color: white;
}

.social-media-icons-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 2.125rem;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.40625rem;
}

.svg-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    width: 1.25rem;
    height: 1.25rem;
    color: #ffffff;
}

.svg-container1 {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    width: 1.5rem;
    height: 1.5rem;
    color: #ffffff;
}

.svg-container2 {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 2.3125rem;
    color: #ffffff;
}

a {
    color: white;
}
</style>
