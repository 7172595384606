<template>
    <h1 class="control-panel-title">Sessie {{ session.adminCode }}</h1>
    <div class="control-panel-content">
        <DialView :value="session.valueMeter1" :min="32" :max="68" :shownumber="1" class="dial"
            title="Stemming<br />Toezichthouders" />

        <DialView :value="session.valueMeter2" :min="38" :max="62" :shownumber="1" class="dial"
            title="Stemming<br />Deelnemers" />

        <DialView :value="session.valueMeter3" :min="36" :max="58" :shownumber="1" class="dial"
            title="Reputatie<br />Media" />

        <br />

        <DialView :value="session.valueMeter4" :min="44" :max="55" :shownumber="1" class="dial"
            title="Stemming<br />Werkgevers" />

        <DialView :value="session.valueMeter5" :min="40" :max="56" :shownumber="1" class="dial"
            title="Kwaliteit<br />Dienstverlening" />
    </div>

    <h3 class="control-panel-title">Sessie aansturen</h3>

    <AdminStartDilemmas v-if="!session.isDummy" :session="session" />

    <h3 class="control-panel-title" style="margin-top: 1em;">Meters aanpassen</h3>
    <AdminChangeMeters v-if="!session.isDummy" :session="session" />

    <h3 class="control-panel-title" style="margin-top: 1em;">Log</h3>
    <AdminSessionDetailLogs v-if="!session.isDummy" />
</template>

<script>
import AdminSessionDetailLogs from '@/components/admin/AdminSessionDetailLogs.vue';
import api from '@/repository/api.js'
import datetimeutils from '@/utils/datetimeutils.js'
import AdminStartDilemmas from '@/components/admin/AdminStartDilemmas.vue'
import AdminChangeMeters from '@/components/admin/AdminChangeMeters.vue'
import DialView from '@/components/controlpanel/DialView.vue'

export default {
    data() {
        return {
            logs: [],
            elapsedTime: '--',
            session: {
                isDummy: true,
                startTime: '--',
                valueMeter1: 50,
                valueMeter2: 50,
                valueMeter3: 50,
                valueMeter4: 50,
                valueMeter5: 50
            }
        };
    },
    async mounted() {
        this.refresh();
        // Set up an interval to fetch sessions every 3 seconds
        this.intervalId = setInterval(this.refresh, 3000);
    },
    beforeUnmount() {
        clearInterval(this.intervalId);
    },
    methods: {
        refresh: async function () {
            const adminCode = this.$route.params.adminCode;
            const token = this.$store.state.sessionToken;
            // console.log(`Fetching data for adminCode: ${adminCode}`);
            this.fetchSession(token, adminCode);
            this.fetchLogs(token, adminCode);
        },
        fetchLogs: async function (token, adminCode) {
            try {
                const response = await api.fetchLogs(token, adminCode);
                this.logs = response.data.data;
            }
            catch (error) {
                console.error("Error fetching logs: ", error);
            }
        },
        async fetchSession(token, adminCode) {
            try {
                const response = await api.fetchSession(token, adminCode);
                this.session = response.data.data;
                // Show elapsed time
                const now = new Date();
                const startTime = new Date(this.session.startTime);
                const diffInMinutes = Math.floor((now - startTime) / (60 * 1000));
                this.elapsedTime = `${diffInMinutes} minuten bezig`;
            }
            catch (error) {
                console.error("An error occurred while fetching session: ", error);
            }
        },
        formatDatetime(timestamp) {
            return datetimeutils.formatDate(timestamp);
        }
    },
    components: {
        AdminSessionDetailLogs,
        AdminStartDilemmas,
        AdminChangeMeters,
        DialView
    }
}
</script>

<style scoped>
.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table th,
.custom-table td {
    text-align: left;
    padding: 8px;
}

tbody tr:nth-child(odd) {
    background-color: white;
}

h1 {
    margin-top: 1.69em;
}

.dial-container {
    font-size: 13pt;
    display: inline-block;
}
</style>
