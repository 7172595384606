<template>
    <div class="container">
        <div class="row">
            <b-carousel id="carousel1" v-model="slide" controls indicators fade img-width="1024" img-height="480"
                class="mb-4" background="#ababab" :interval="4000">
                <b-carousel-slide v-for="(image, index) in images" :key="index" :img-src="image.src" :img-alt="image.alt"
                    :text="image.title">
                </b-carousel-slide>
            </b-carousel>
        </div>
    </div>
</template>
  
<script>
import Image1 from '@/assets/carousel_b_1.jpg';
import Image2 from '@/assets/carousel_b_2.jpg';
import Image3 from '@/assets/carousel_b_3.jpg';

export default {
    name: 'CarouselView',
    data() {
        return {
            slide: 0,
            images: [
                {
                    src: Image1,
                    alt: 'Image 1',
                    title: 'Verzeker uw toekomst'
                },
                {
                    src: Image2,
                    alt: 'Image 2',
                    title: 'Het eerste volledig digitale pensioenfonds'
                },
                {
                    src: Image3,
                    alt: 'Image 2',
                    title: 'Altijd online, altijd veilig'
                },
            ]
        };
    }
};
</script>

<style>
.b-carousel {
    min-height: 480px;
    /* or whatever value you want */
}

.carousel-item {
    position: relative;
}

.carousel-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    /* Adjust the height as needed to avoid covering the caption */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.9));
    pointer-events: none;
    /* So that the gradient doesn't interfere with any interactions with the image */
    z-index: 1;
    /* Place it above the image */
}

.carousel-item img {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    z-index: 0;
    /* Place it below the gradient */
}

.carousel-item p {
    font-family: var(--font-caption);
    font-size: 45pt;
    text-shadow: 0 0 1px black, 0 0 4px black, 0 0 10px black;
    line-height: 0.8em;
    margin-bottom: 0.5em;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    height: 3.3125rem;
    width: 3.3125rem;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    background: #f2f6ff;
    padding: 0.5rem;
    border: 0.0625rem solid white;
}

.carousel-control-next-icon:hover,
.carousel-control-prev-icon:hover {
    border: 0.0625rem solid var(--color-blue);
}

.carousel-control-next-icon {
    background-image: url("@/assets/ic_next.png");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;

}

.carousel-control-prev-icon {
    background-image: url("@/assets/ic_prev.png");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
}</style>
