<template>
    <HeaderView />

    <div class="container-fluid">

        <BannerView v-if="showBanner" />

        <div class="container mt-5">

            <div class="row">
                <div class="mission-container">
                    <p class="caption">Contact</p>
                    <p>
                        Lorem ipsum
                    </p>
                </div>
            </div>
        </div>

        <div class="header-image image-3"></div>

        <div class="container mt-5">
            <div class="row">
                <div class="news-container">

                </div>
            </div>
        </div>

        <!-- <div class="header-image image-4" style="margin-top:3rem;"></div> -->
    </div>

    <FooterView />
</template>

<script>
import HeaderView from '@/components/HeaderView.vue';
import BannerView from '@/components/BannerView.vue';
import FooterView from '@/components/FooterView.vue';

export default {
    name: 'InfoBeveiliging',
    components: {
        HeaderView, BannerView, FooterView
    }
};
</script>

<style scoped>
.container-fluid {
    margin-bottom: 3em;
}

.mission-container {
    box-sizing: border-box;
    max-width: 73.125rem;
    padding-bottom: 1.3125rem;
    background: white;
}

.serene-future-mission {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 3.75rem;
}

.serene-future-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.journey-guide {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 1.75rem;
    margin-left: 2.9375rem;
}

.serene-future-text {
    box-sizing: border-box;
    flex: 0 1 auto;
    margin-left: 1.875rem;
}

.journey-guide1 {
    box-sizing: border-box;
    flex: 0 1 auto;
    margin-left: -1.1875rem;
}

.signature {
    font-size: 15pt;
    display: block;
    margin-top: 2rem;
}

.header-image {
    width: 100%;
    height: 11em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.image-1 {
    background-image: url(@/assets/carousel_2.jpg);
}

.image-2 {
    background-image: url(@/assets/about-us.jpg);
}

.image-3 {
    background-image: url(@/assets/office-with-logo.jpg);
}

.image-4 {
    background-image: url(@/assets/about-us-3.jpg);
}

.news-container {
    background: #f6f9ff;
    padding: 2.5em;
}

.news-container p {
    text-align: justify;
}

.news-container h2 {
    color: #444;
    font-weight: normal;
    margin-bottom: 0.5em;
    font-size: 1.9rem;
}
</style>