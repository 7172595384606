<template>
  <router-view></router-view>
</template>

<script>
import api from '@/repository/api.js'

const debugSessionStatus = false;

export default {
  name: 'App',

  // data() {
  //   return {
  //     unreadCount: -1
  //   }
  // },

  // computed: {
  //   unreadMessages() {
  //     if (!this.$store.state.sessionToken) {
  //       return -1;
  //     }
  //     const unreadEmail = this.$store.state.session.steps.filter(msg => msg.isRead === false).length;
  //     if (debugSessionStatus) {
  //       console.log(`unread inbox: ${unreadEmail}`);
  //     }
  //     return unreadEmail;
  //   }
  // },

  // watch: {
  //   unreadMessages() {
  //     if (this.unreadMessages > this.unreadCount && this.unreadCount !== -1) {
  //       this.playSound();
  //       this.unreadCount = this.unreadMessages;
  //     }
  //   }
  // },

  mounted() {
    this.refresh();
    this.refreshInterval = setInterval(this.refresh, 3000);
  },

  beforeUnmount() {
    clearInterval(this.refreshInterval);
  },

  methods: {

    refresh() {
      const token = this.$store.state.sessionToken;
      if (token && !this.$store.state.isAdmin) {
        this.fetchSession(token);
      }
    },

    async fetchSession(token) {
      try {
        const response = await api.fetchSession(token);
        const session = response.data.data;
        if (debugSessionStatus) {
          console.log(`Game progress: ${session.progress}. State: ${session.isPaused ? 'paused' : 'playing'}. [${session.valueMeter1}, ${session.valueMeter2}, ${session.valueMeter3}, ${session.valueMeter4}, ${session.valueMeter5}]`);
        }
        this.$store.dispatch('setSession', session);
      } catch (error) {
        console.error("An error occurred while fetching session: ", error);
      }
    },

    // playSound() {
    //   let audio = new Audio(require('@/assets/sounds/notification.flac'));
    //   audio.play();
    // }
  }
};
</script>

<style scoped>
#logo {
  width: 100px;
  /* adjust as necessary */
  height: auto;
}
</style>
