<template>
    <div class="login-page">
        <div class="login-box rounded welcome-container">
            <h2>Welkom!</h2>
            <img src="@/assets/logo_large.png" alt="Logo" width="200" />
            <p class="instructions">Log in met je administratiecode om te beginnen:</p>
            <!-- <input v-model="adminCode" type="text" class="form-control input-lg" /> -->
            <InputView v-model="adminCode" hint="Code" type="password" />
            <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}
            </div>
            <ButtonView text="Start" @click="login" />
        </div>
    </div>
</template>
  
<script>
import ButtonView from '@/components/controlpanel/ButtonView.vue'
import InputView from '@/components/controlpanel/InputView.vue'
import { mapActions } from 'vuex'

export default {
    name: 'LoginView',
    data() {
        return {
            adminCode: '',
            errorMessage: ''
        };
    },
    methods: {
        ...mapActions(['loginUser']),
        async login() {
            try {
                const response = await this.loginUser(this.adminCode);
                console.log(response.data);
                if (response.data.isAdmin) {
                    // This is safe to do, because we will always check the sessionToken server-side. If anyone fakes this response argument, they won't be able to access anything.
                    this.$router.push('/admin');
                } else {
                    this.$router.push('/control-panel');
                }
            }
            catch (error) {
                console.error(error);
                this.errorMessage = 'Ongeldige administratiecode. Probeer het opnieuw.';
            }
        }
    },
    components: { ButtonView, InputView }
}
</script>
  
<style>
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
}

h2 {
    font: 700 2.9375rem Roboto;
    color: var(--color-blue);
}

.alert {
    border-radius: 1rem;
}

.instructions {
    margin-top: 0.625rem;
    font: 700 1.78125rem Roboto;
    color: #5f8bff;
    text-align: center;
}

.welcome-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    justify-content: flex-start;
    max-width: 33.25rem;
    padding: 3.25rem 5.5625rem 5.625rem;
    background: #f0f4ff;
    border: 0.09375rem solid #5f8bff;
    border-radius: 0.8125rem;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
}
</style>
