<template>
    <div class="dial-container">

        <!-- Background Image -->
        <div :class="['dial-image', imageClass]"></div>

        <!-- Title -->
        <div class="dial-title" v-html="title"></div>

        <!-- Dial Status -->
        <div v-if="shownumber == 0" class="dial-status">{{ dialStatusName }}</div>
        <div v-if="shownumber == 1" class="dial-status">{{ valueString }}</div>

    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: true
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        title: {
            type: String,
            default: 'Dial'
        },
        shownumber: {
            type: Number,
            default: 0
        }
    },
    computed: {
        valueString() {
            if (this.value == 50) {
                return "0";
            } else if (this.value < 50) {
                return (this.value - 50);
            } else {
                return "+" + (this.value - 50);
            }
        },
        dialStatus() {
            const v = Math.max(this.min, Math.min(this.max, this.value));
            // const percent = (v - this.min) / (this.max - this.min);

            // if (percent <= 0.2) return 'very bad';
            // else if (percent <= 0.4) return 'bad';
            // else if (percent <= 0.6) return 'medium';
            // else if (percent <= 0.8) return 'good';
            // else return 'very good';

            // 50 is the start value. This should be the lower end of 'voldoende'.
            // Above 50 to 'max' we have 2 parts: voldoende and goed.
            // Below 50 to 'min' we have 3 parts: matig, slecht, zeer slecht.
            // Let's calculate how large those parts are:
            const middle = 50;
            const partLow = (middle - this.min) / 3;
            const partHigh = (this.max - middle) / 2;

            console.log(`Chunks for ${this.title} are: 
                ${this.min} to ${middle - 2 * partLow} is 'zeer slecht',
                ${middle - 2 * partLow + 1} to ${middle - partLow} is 'slecht',
                ${middle - partLow + 1} to ${middle - 1} is 'matig',
                ${middle} to ${middle + partHigh - 1} is 'voldoende', and
                ${middle + partHigh + 1} to ${this.max} is 'goed'.`);

            if (v <= middle - 2 * partLow) return 'very bad';
            else if (v <= middle - partLow) return 'bad';
            else if (v < middle) return 'medium';
            else if (v < middle + partHigh) return 'good';
            else return 'very good';
        },
        dialStatusName() {
            switch (this.dialStatus) {
                case 'very bad': return 'zeer slecht';
                case 'bad': return 'slecht';
                case 'medium': return 'matig';
                case 'good': return 'voldoende';
                case 'very good': return 'goed';
            }
            return 'matig';
        },
        imageClass() {
            switch (this.dialStatus) {
                case 'very bad': return 'very-bad';
                case 'bad': return 'bad';
                case 'medium': return 'medium';
                case 'good': return 'good';
                case 'very good': return 'very-good';
            }
            return 'very-good';
        }
    }
}
</script>

<style scoped>
.dial-container {
    position: relative;
    width: 12em;
    height: 10em;
    text-align: center;
    /* margin: 0.5em; */
    padding: 1em;
    /* background: white;
    border-radius: 1em; */
    font-size: 16pt;
}

.dial-title {
    position: absolute;
    top: calc(55% - 2em);
    left: 25%;
    font-size: 14pt;
    width: 50%;
    /* font-weight: bold; */
    line-height: 1.2em;
    color: #5e5c5c;
    text-transform: lowercase;
}

.dial-image {
    position: absolute;
    top: 0.75em;
    left: 0.75em;
    bottom: 0.75em;
    right: 0.75em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.dial-status {
    position: absolute;
    top: calc(55% + 1em);
    font-weight: bold;
    left: 0;
    width: 100%;
}

/* You can replace these with your actual image paths */
.very-bad {
    background-image: url('@/assets/dial-very-bad.png');
}

.bad {
    background-image: url('@/assets/dial-bad.png');
}

.medium {
    background-image: url('@/assets/dial-medium.png');
}

.good {
    background-image: url('@/assets/dial-good.png');
}

.very-good {
    background-image: url('@/assets/dial-very-good.png');
}
</style>
