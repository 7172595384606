<template>
    <div class="control-panel-content container-fluid">
        <h3>Er zijn {{ unreadCount }} nieuwe bericht(en).</h3>
        <table class="table voicemails-list">
            <thead>
                <th>Afzender</th>
                <th>Beluisteren</th>
            </thead>
            <tbody>
                <tr v-for="voicemail in voicemails" :key="voicemail.id"
                    :class="[voicemail.isRead == 1 ? 'voicemail-read' : 'voicemail-unread']">
                    <td class="voicemail-name">{{ voicemail.from }}</td>
                    <td class="voicemail-audio">
                        <audio controls :src="voicemail.audioFile" @play="markAsRead(voicemail)"></audio>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import api from '@/repository/api.js'
import { game } from '@/repository/gamestate';

export default {
    data() {
        return {
            voicemails: [],
        }
    },
    computed: {
        unreadCount() {
            return this.voicemails.filter(msg => msg.isRead === false).length;
        }
    },
    mounted() {
        this.voicemails = this.filterVoicemails();
        console.log(this.voicemails);
    },
    methods: {
        filterVoicemails() {
            let vms = [];
            const steps = this.$store.state.session.steps;
            const now = Date.now();
            if (steps) {
                // For each of the known items in the inbox, check if they have a matching
                // startTime in the session and show it if so.
                game.inbox.forEach(step => {
                    const dilemma = steps.find(s => s.dilemmaId == step.dilemmaId);
                    if (dilemma
                        && dilemma.startTime
                        && now > new Date(dilemma.startTime).getTime()
                        && dilemma.isVoicemail) {
                        vms.push({
                            ...dilemma,
                            ...step
                        });
                    }
                });
            }
            return vms;
        },
        markAsRead(voicemail) {
            if (!voicemail.isRead) {
                const token = this.$store.state.sessionToken;
                api.emailOpened(token, voicemail.dilemmaId);
                this.$store.dispatch('markAsRead', voicemail.dilemmaId);
            }
        }
    }
};
</script>

<style scoped>
h3 {
    margin: 1rem;
}

table {
    border-collapse: unset;
    border-spacing: 3px;
}

.table th {
    border-top: 0;
    border-bottom: 0;
}

.control-panel-voicemail {
    padding: 20px;
}

.voicemails-list {
    list-style-type: none;
    padding: 0;
}

.voicemails-list li {
    margin-bottom: 20px;
}

tr.voicemail-read {
    border-radius: 1rem;
}

td a {
    color: black;
}

.voicemail-read td,
.voicemail-unread td {
    font-size: 1.6rem;
}

.voicemail-read {
    background-color: transparent;
}

.voicemail-unread {
    font-weight: 700;
}

.voicemail-unread td {
    background-color: #DCE6FF;
}

.voicemail-unread td:first-child {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}

td:first-child {
    padding-left: 1.5rem;
    padding-right: 0;
}

.voicemail-unread td:last-child {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

tr,
td {
    border: 0;
    border-top: 0;
    /* Resetting any previous borders */
}

.voicemail-name {
    width: 30%;
}

.voicemail-audio {
    width: 30%;
    line-height: 0;
}

audio {
    background: white;
    border: 2px solid var(--color-blue);
    border-radius: 27px;
    width: 100%;
    /* transform: scale(1.25) translate(-7%, -8px); */
}
</style>

