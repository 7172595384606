<template>
    <p v-if="remainingSeconds > 0">
        <label>Nog {{ remainingPretty }} om te beslissen!</label>
        <b-progress :value="remainingSeconds" :max="durationSeconds" striped animated></b-progress>
    </p>
    <p v-else>
        <label>Helaas, het is te laat om nog te beslissen.</label>
    </p>
</template>

<script>
export default {
    data() {
        return {
            value: 50,
            max: 100
        }
    },
    props: {
        durationSeconds: {
            type: Number,
            required: true
        },
        remainingSeconds: {
            type: Number,
            required: true
        }
    },
    computed: {
        remainingPretty() {
            return new Date(this.remainingSeconds * 1000).toISOString().substr(14, 5);
        }
    }
}
</script>
  
<style scoped>
p {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
</style>
