<template>
    <div class="row">
        <div class="mission-container">
            <p class="caption">Onze Missie</p>
            <div class="serene-future-mission">
                <div class="serene-future-container">
                    <p class="future-heading">1</p>
                    <p class="serene-future-text">Bij Serene Future zetten we ons in om uw vertrouwen waard te zijn en u
                        te helpen uw toekomst met vertrouwen tegemoet te treden. Samen bouwen we aan een fundament dat
                        rust, stabiliteit en welzijn bevordert, zodat u kunt genieten van een serenere toekomst.</p>
                </div>
                <div class="journey-guide">
                    <p class="big-heading">2</p>
                    <p class="journey-guide1">Ons team van ervaren professionals staat klaar om u te begeleiden op uw
                        reis naar een serenere toekomst. We hebben experts op het gebied van financiën, beleggingen en
                        pensioenplanning die u ondersteunen bij elke stap van het proces.

                        <span class="signature">Penn-Sean, Fons (CEO)</span>
                        <img src="@/assets/signature.png" width="240" />
                    </p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'HomeBlockMission'
};
</script>

<style scoped>
.mission-container {
    box-sizing: border-box;
    max-width: 73.125rem;
    padding-bottom: 1.3125rem;
    background: white;
}

.serene-future-mission {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 3.75rem;
}

.serene-future-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.future-heading {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-top: 1.875rem;
    padding-bottom: 4.5rem;
    font: 900 12.5rem Roboto;
    color: #f2f2f2;
}

.journey-guide {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 1.75rem;
    margin-left: 2.9375rem;
}

.big-heading {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-bottom: 0.625rem;
    font: 900 12.5rem Roboto;
    color: #f2f2f2;
}

.serene-future-text {
    box-sizing: border-box;
    flex: 0 1 auto;
    margin-left: 1.875rem;
}

.journey-guide1 {
    box-sizing: border-box;
    flex: 0 1 auto;
    margin-left: -1.1875rem;
}

.signature {
    font-size: 15pt;
    display: block;
    margin-top: 2rem;
}
</style>
